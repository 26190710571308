/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import FieldSupportNavBar from '../../../../layouts/field-support/FieldSupportNavBar';
import CustomerDetailsSharedBody from '../../../shared/customer-details/CustomerDetailsSharedBody';
import CustomerSupportCustomers from '../../customer-support/customers/CustomerSupportCustomers';
import SupportCustomersTable from '../../../shared/customers/shared/SupportCustomersTable';
import ReactPaginate from 'react-paginate';

const FieldSupportCustomerView = () => {
  const firstName = sessionStorage.getItem('firstName');

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <FieldSupportNavBar currentPage="Customers" position={firstName} />

        <div className="mt-5">
          <div className="table-responsive">
            <SupportCustomersTable />
          </div>
        </div>
      </main>
    </div>
  );
};

export default FieldSupportCustomerView;
