import { useEffect } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { LOGGED_IN_USER_URL } from '../../../../services/api/auth/AuthUrl';

const useCheckFiveDepartments = (department1, department2, department3, department4, department5, history) => {
  useEffect(() => {
    const checkFiveDepartments = async () => {
      try {
        const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        if (![department1, department2, department3, department4, department5].includes(response.data.department_id)) {
          sessionStorage.clear();
          sessionStorage.setItem('token', '');
          sessionStorage.setItem('email', '');
          sessionStorage.setItem('officialEmail', '');
          sessionStorage.setItem('firstName', '');
          sessionStorage.setItem('lastName', '');
          sessionStorage.setItem('departmentId', '');
          sessionStorage.setItem('roleId', '');

          sessionStorage.setItem('country', '');
          history.push('/login');
          toastr.info('You are not allowed to view this page');
        }
      } catch (e) {
        toastr.error('Error fetching user data');
      }
    };

    checkFiveDepartments();
  }, [department1, department2, department3, department4, department5, history]);
};

export default useCheckFiveDepartments;
