/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-empty */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { LOGGED_IN_USER_URL } from '../../../../services/api/auth/AuthUrl';
import { DATA_ANALYSIS, SUPER_ADMIN } from '../../../../utils/departments/helpers';
import profile from '../../../../assets/img/icons/Profile.png';
import ViewUserProfile from '../../shared/staff/ViewUserProfile';
import DataAnalysisNavBar from '../../../layouts/data-analysis /DataAnalysisNavBar';

const DataAnalysisOverview = () => {
  useEffect(() => {
    checkDepartment();

  }, []);

  const checkDepartment = async () => {
    try {
      const response = await axios.get(`${LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (![DATA_ANALYSIS, SUPER_ADMIN].includes(response.data.department_id)) {
        sessionStorage.clear();
        toast.info('You are not allowed to view this page');
        window.location.href = '/';
      }
    } catch (e) {}
  };

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <DataAnalysisNavBar currentPage="Overview" profileImage={profile} position />
        </div>

        <div className="col-12 after-summary">
          <ViewUserProfile />
          <div className="row">
            <div className="col-lg-12 p-0">
              <div className="col-lg-12 p-3">
                <div className="col-lg-12 d-flex p-0">
                  <h6 className="col-lg-8 mr-auto">Data Analysis Summary</h6>
                  <p className="ops-opt copy-font d-none d-md-flex">
                    <small className="mr-2"> Filter By:</small>
                    <span>
                      <select className="form-control">
                        <option>Month</option>
                      </select>
                    </span>
                    <span>
                      <select className="form-control">
                        <option>Year</option>
                      </select>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>

        
        </div>
      </main>
      <ToastContainer />
    </div>
  );
};


export default DataAnalysisOverview;
