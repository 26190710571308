import React, { Component } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import PropTypes from 'prop-types';
import { accounts, admin, API_URL } from '../../../../../utils/config';
import Extensions from '../../../shared/customers/extensions/Extensions';
import AccountsNavBar from '../../../../layouts/accounts/AccountsNavBar';
import { ASSET_TEAM } from '../../../../../utils/departments/helpers';
import AssetTeamNavBar from '../../../../layouts/asset-team/AssetTeamNavBar';

class CustomerExtension extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      role: '',
    };
  }

  componentDidMount() {
    this.fetchLoggedOnUser();
    this.checkIfLoggedIn();
    this.getUserDetails();
  }

  getUserDetails() {
    axios({
      method: 'get',
      url: `${API_URL}/employee/loggedOnEmployee`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        if (res.data) {
          this.setState({
            firstName: res.data.firstName,
          });
        } else {
          this.setState({
            firstName: '',
          });
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  fetchLoggedOnUser() {
    axios({
      method: 'get',
      url: `${API_URL}/employeeReg/logged`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      json: true,
    })
      .then((response) => {
        this.setState({
          role: response.data.role_id,
        });

        if (response.data.id) {
          if (response.data.department_id !== `${accounts}` && response.data.department_id !== `${admin}` && response.data.department_id !== `${ASSET_TEAM}`) {
            // eslint-disable-next-line react/destructuring-assignment
            this.props.history.push('/login');
            toastr.info('You are not allowed to view this page');
          }
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  checkIfLoggedIn() {
    if (!sessionStorage.getItem('token')) {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.history.push('/login');
    }
  }

  render() {
    const { firstName, role } = this.state;
    const departmentId = sessionStorage.getItem('departmentId');
    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          {departmentId === `${ASSET_TEAM}` ? (
            <AssetTeamNavBar currentPage="Customer Extension" position={firstName} role_id={role} />
          ) : (
            <AccountsNavBar currentPage="Customer Extension" position={firstName} role_id={role} />
          )}
          <Extensions />
        </main>
      </div>
    );
  }
}

CustomerExtension.propTypes = {
  // eslint-disable-next-line react/require-default-props
  history: PropTypes.shape(),
};
export default CustomerExtension;
