/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable import/prefer-default-export */
import { useState, useReducer } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import qs from 'qs';
import toastr from 'toastr';
import * as geniatech from '../../../services/api/shared/GeniatechUrl';
import * as types from '../../../constants/types/shared/customers/CustomerDetailsTypes';
import { customerReducer } from '../../../reducers/shared/customers/customerReducer';
import * as load from '../../../constants/types/shared/LoadingTypes';
import * as url from '../../../services/api/shared/extensions/Extensions';
import { ADD_LTE_USER_URL } from '../../../services/api/sales/customers/CustomersUrl';

export const useCustomer = () => {
  const extendState = {
    duration: '',
    reason: '',
  };

  const [search, setSearch] = useState('');
  const [option, setOption] = useState('');
  const [country, setCountry] = useState('');
  const [extend, setExtend] = useState(extendState);
  const [reason, setReason] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [router, setRouter] = useState('');

  const [
    {
      customers,
      isLoading,
      password,
      accounts,
      gettingPassword,
      extendingCustomer,
      gettingDetails,
      address,
      itemprice,
      details,
      session,
      createdAt,
      username,
      deleting,
      customer,
      showModal,
      gettingDate,
      installationDate,
    },
    dispatch,
  ] = useReducer(customerReducer, {
    customers: [],
    isLoading: false,
    password: [],
    accounts: [],
    gettingPassword: false,
    extendingCustomer: false,
    gettingDetails: false,
    address: [],
    itemprice: [],
    details: [],
    session: [],
    createdAt: '',
    username: '',
    deleting: false,
    customer: {},
    showModal: false,
    gettingDate: false,
    installationDate: {},
  });

  const onSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const onRouterChange = (e) => {
    const { value } = e.target;
    setRouter(value);
  };

  const onOptionChange = (e) => {
    const { value } = e.target;
    setOption(value);
  };

  const onCountryChange = (e) => {
    const { value } = e.target;
    setCountry(value);
  };

  const extendCustomer = async (data, extendLog) => {
    dispatch({ type: types.START_EXTENDING_CUSTOMER });
  
    try {
      const response = await axios.post(`${url.EXTEND_CUSTOMER_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
  
      if (response.data.status === true) {
        try {
          const res = await axios.post(`${url.LOG_EXTENSION_URL}`, extendLog, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          });
  
          if (res.data.id) {
            toastr.success('User extended successfully!');
            // setTimeout(() => {
            //   window.location.reload();
            // }, 2000);
            setExtend(extendState);
          } else {
            toastr.info('Unable to log extension');
          }
        } catch (e) {
          toastr.error('Error logging extension!');
        }
      } else {
        toastr.info(response.data.message || 'Unable to extend user!');
      }
    } catch (e) {
      toastr.error(e.response.data && e.response.data.message || 'Error extending user!');
    } finally {
      dispatch({ type: types.STOP_EXTENDING_CUSTOMER });
    }
  };
  

  const onExtendChange = (e) => {
    const { name, value } = e.target;
    setExtend({
      ...extend,
      [name]: value,
    });
  };

  const searchForUserByEmail = async (data) => {
    dispatch({
      type: load.LOADING_STARTS,
    });

    try {
      const response = await axios.post(`${geniatech.GENIATECH_USER_INFORMATION_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data === 'customer info could not be retreived') {
        dispatch({
          type: load.LOADING_STOPS,
        });

        toast.info('No user found!');
      } else {
        dispatch({
          type: types.FETCHED_CUSTOMER,
          payload: response.data,
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: load.LOADING_STOPS,
        });
      }
    }
  };

  const searchByIMSI = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${ADD_LTE_USER_URL}/searchbyimsi`, qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.status === 'success') {
        dispatch({
          type: types.FETCHED_CUSTOMER,
          payload: response.data.data,
        });
      } else {
        toast.info('No user found!');
        dispatch({ type: load.LOADING_STOPS });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const searchByPhoneOrMac = async (data) => {
    dispatch({
      type: load.LOADING_STARTS,
    });

    try {
      const response = await axios.post(`${geniatech.GENIATECH_GET_USER_WITH_PHONE_OR_MAC_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data === 'customer info could not be retreived') {
        toast.info('No user found!');
        dispatch({
          type: load.LOADING_STOPS,
        });
      } else {
        dispatch({
          type: types.FETCHED_CUSTOMER,
          payload: response.data,
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e) {
        dispatch({
          type: load.LOADING_STOPS,
        });
      }
    }
  };

  const searchByReference = async (data) => {
    dispatch({
      type: load.LOADING_STARTS,
    });
    try {
      const response = await axios.post(`${geniatech.SEARCH_FOR_CUSTOMER_BY_REFRENCE_URL}`, qs.stringify(data));
      if (response.data === 'customer info could not be retreived') {
        toast.info('No user found!');
        dispatch({
          type: load.LOADING_STOPS,
        });
      } else {
        dispatch({
          type: types.FETCHED_CUSTOMER,
          payload: response.data,
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e) {
        dispatch({
          type: load.LOADING_STOPS,
        });
      }
    }
  };

  const searchByName = async (data) => {
    dispatch({
      type: load.LOADING_STARTS,
    });

    try {
      const response = await axios.get(
        `${geniatech.GENIATECH_GET_USER_BY_NAME_URL}?name=${data.name}&country=${data.country}&page=${data.page}&limit=50`,

        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      if (response.data.message === 'could not find any customer with this name') {
        dispatch({
          type: load.LOADING_STOPS,
        });

        toast.info('No user found!');
      } else if (typeof response.data === 'string') {
        dispatch({
          type: load.LOADING_STOPS,
        });
        toast.info('Unable to get this user');
      } else {
        setPageCount(response.data.total / 50);
        // Debugging step

        dispatch({
          type: types.FETCHED_CUSTOMER,
          payload: response.data.data.map((user) => ({
            username: user.UserName,
            name: user.Name,
            mail: user.Mail,
            address: user.Mobile, // Renaming Mobile to address
            customer_ref: user.Customer_Number, // Renaming Customer_Number to customer_ref
            attribute: user.attribute,
            value: user.value,
            agent: null,
            basestation: null,
            call_count: 0,
            comment: 'N/A',
            comment_addedat: 'N/A',
            comment_addedby: 'N/A',
            country: null,
            details: 'N/A',
            last_call_time: '',
            region: null,
            zone: null,
          })),
        });
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: load.LOADING_STOPS,
        });
      }
    }
  };

  const getPassword = async (macData, emailData) => {
    dispatch({ type: types.START_GETTING_PASSWORD });
    try {
      await Promise.all([
        axios.post(`${geniatech.GENIATECH_NEW_GET_USER_PASSWORD_URL}`, qs.stringify(macData)),
        axios.post(`${geniatech.SEARCH_FOR_CUSTOMER_URL}`, qs.stringify(emailData), {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
      ]).then((responses) => {
        dispatch({
          type: types.GET_PASSWORD,
          payload: {
            teleportPassword: responses[0].data,
            accounts: responses[1].data.users[0].accounts,
          },
        });
      });
    } catch (e) {
      toast.info('Something went wrong!');
      if (e.response) {
        dispatch({ type: types.STOP_GETTING_PASSWORD });
      }
    }
  };

  const getUserDetails = async (mac) => {
    const data = {
      macid: mac,
    };
    const macData = {
      mac_id: mac,
    };
    const addressData = qs.stringify({
      action: 'getCustomerAddress',
      mac,
    });
    const getPlan = qs.stringify({
      action: 'getItemPrice',
      TB_PREF: '0_',
      user_id: 'cgate.tzt',
      password: 'megamound',
      macid: mac,
      item: 'INTERNET',
    });
    const sessionData = {
      macaddress: mac,
    };
    dispatch({
      type: types.START_FETCHING_CUSTOMER_DETAILS,
    });

    try {
      await axios
        .post(`${geniatech.GET_CUSTOMER_ADDRESS_URL}`, addressData)
        .then((result) => {
          Promise.all([
            axios.post(`${geniatech.GENIATECH_GET_USER_AUTH_URL}`, data, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
              },
            }),
            axios.post(`${geniatech.GET_USER_PLAN_URL}`, getPlan),
            axios.post(`${geniatech.GENIATECH_GET_USER_WITH_ROUTER_TAG}`, macData, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
              },
            }),
            axios.post(`${geniatech.GENIATECH_GET_USER_SESSION_URL}`, sessionData, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
              },
            }),
            axios.post(
              `${geniatech.SEARCH_FOR_ACCOUNT_URL}`,
              { mac_id: mac },
              { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } },
            ),
          ]).then((responses) => {
            console.log(responses[4].data);
            dispatch({
              type: types.FETCHED_CUSTOMER_DETAILS,
              payload: {
                address: result.data[0].address,
                details: responses[0].data,
                session: responses[3].data,
                itemprice: responses[1].data.itemprice,
                createdAt: responses[2].data.created_at,
                username: responses[2].data.username,
                installationDate: responses[4].data,
              },
            });
          });
        })
        .catch((err) => {
          if (err) {
            toast.info('Unable To Get Customer');
          }
        });
    } catch (e) {
      toast.info('Something went wrong!');
      dispatch({
        type: types.STOP_FETCHING_CUSTOMER_DETAILS,
      });
    }
  };

  const deleteCustomerAccount = async (data) => {
    dispatch({
      type: types.DELETING_ACCOUNT,
    });
    try {
      const response = await axios.post(`${geniatech.DELETE_CUSTOMER_ACCOUNT_URL}`, data);
      if (response.data.result === true) {
        toast.success('Customer has been deleted!');
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        // if (response.data.status === false) {
        toast.info('Unable to delete account');
        dispatch({ type: types.STOP_DELETING_ACCOUNT });
        // }
      }
    } catch (e) {
      if (e) {
        toast.info('Unable to delete account');
        dispatch({
          type: types.STOP_DELETING_ACCOUNT,
        });
      }
    }
  };

  const onDeleteChange = (e) => {
    setReason(e.target.value);
  };
  const searchByRouterTag = async (data) => {
    dispatch({
      type: load.LOADING_STARTS,
    });

    try {
      const response = await axios.post(`${geniatech.GENIATECH_GET_USER_WITH_ROUTER_TAG}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.id) {
        dispatch({
          type: types.FETCHED_CUSTOMER,
          payload: response.data,
        });
      } else {
        toast.info('Unable to get user!');
        dispatch({
          type: load.LOADING_STOPS,
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e) {
        dispatch({
          type: load.LOADING_STOPS,
        });
      }
    }
  };
  const searchWithColonlessMac = async (data) => {
    dispatch({
      type: load.LOADING_STARTS,
    });

    try {
      const response = await axios.post(`${geniatech.SEARCH_FOR_ACCOUNT_URL}`, data, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      });
      if (response.data.message === 'No account with such details') {
        toast.info('No user found!');
        dispatch({
          type: load.LOADING_STOPS,
        });
      } else {
        dispatch({
          type: types.FETCHED_CUSTOMER,
          payload: response.data,
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e) {
        dispatch({
          type: load.LOADING_STOPS,
        });
      }
    }
  };

  return {
    search,
    onSearchChange,
    searchForUserByEmail,
    searchByPhoneOrMac,
    searchByName,
    searchByIMSI,
    customers,
    isLoading,
    onOptionChange,
    option,
    getPassword,
    gettingPassword,
    password,
    accounts,
    onExtendChange,
    extend,
    extendCustomer,
    extendingCustomer,
    getUserDetails,
    gettingDetails,
    details,
    address,
    itemprice,
    createdAt,
    pageCount,
    session,
    username,
    onCountryChange,
    country,
    deleteCustomerAccount,
    deleting,
    onDeleteChange,
    reason,
    onRouterChange,
    router,
    searchByRouterTag,
    customer,
    searchByReference,
    showModal,
    gettingDate,
    installationDate,
    searchWithColonlessMac,
  };
};
