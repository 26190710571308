import React, { useState, useRef } from 'react';
import Papa from 'papaparse';
import axios from 'axios';
import { FaCheckCircle } from 'react-icons/fa';
import { EDIT_RETRIEVED_URL } from '../../../../../services/api/field-support/customers/CustomersUrl';

const RetrievedViewBody = () => {
  const [loader, setLoader] = useState(false);
  const [retrievedCustomers, setRetrievedCustomers] = useState([]);
  const [updatedMacs, setUpdatedMacs] = useState({});
  const [selectedMacs, setSelectedMacs] = useState({});
  const [filter, setFilter] = useState('all');
  const fileInputRef = useRef(null);

  const API_URL = 'https://api.tizeti.com/api/v1/getUserInformation';

  const sampleCsvContent = `Email\nexample1@gmail.com\nexample2@gmail.com\nexample3@gmail.com`;

  const downloadSampleCsv = () => {
    const blob = new Blob([sampleCsvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'sample_retrieved_customers.csv';
    link.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      complete: async (result) => {
        const parsedData = result.data
          .slice(1)
          .map((row) => ({ email: row[0] ? row[0].trim() : '' }))
          .filter((item) => item.email);

        setRetrievedCustomers(parsedData);
        setUpdatedMacs({});
        setSelectedMacs({});

        const macUpdates = {};
        const newSelectedMacs = {};

        await Promise.all(
          parsedData.map(async (customer) => {
            const macAddresses = await getUserInfo(customer.email);
            macUpdates[customer.email] = macAddresses || [];

            // Auto-select if there's only one MAC address
            if (macAddresses && macAddresses.length === 1) {
              newSelectedMacs[customer.email] = macAddresses[0];
            }
          }),
        );

        setUpdatedMacs(macUpdates);
        setSelectedMacs(newSelectedMacs);

        // Ensure ref is defined before accessing value
        if (fileInputRef.current) {
          fileInputRef.current.value = ''; // Clear file input
        }
      },
      header: false,
      skipEmptyLines: true,
    });
  };

  const handleRefresh = () => {
    setRetrievedCustomers([]);
    setUpdatedMacs({});
    setSelectedMacs({});

    // Ensure ref is defined before accessing value
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear file input
    }
  };

  const getUserInfo = async (email) => {
    try {
      const response = await axios.post(
        API_URL,
        { email },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } },
      );

      return response.data.length > 0 ? response.data.map((entry) => entry.username) : [];
    } catch {
      return [];
    }
  };

  const handleRetrieve = async (email) => {
    const macAddress = selectedMacs[email];

    if (!macAddress) return; // Prevent invalid requests

    setLoader(true);
    try {
      await axios.get(`${EDIT_RETRIEVED_URL}?mac=${macAddress}&email=retrieved@tizeti.com`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      });

      setUpdatedMacs((prev) => ({ ...prev, [email]: 'retrieved' }));
    } catch (error) {
      setUpdatedMacs((prev) => ({
        ...prev,
        [email]: (error.response.data && error.response.data.message) || 'Error retrieving MAC',
      }));
    } finally {
      setLoader(false);
    }
  };

  const handleRetrieveAll = () => {
    Object.keys(selectedMacs).forEach((email) => handleRetrieve(email));
  };

  const filteredCustomers = retrievedCustomers.filter((customer) => {
    const macCount = (updatedMacs[customer.email] && updatedMacs[customer.email].length) || 0;
    if (filter === 'single') return macCount === 1;
    if (filter === 'multiple') return macCount > 1;
    return true;
  });

  return (
    <div className="container mt-4">
      <h2 className="text-center">Retrieve Customers</h2>
      <div className="d-flex flex-wrap gap-2 justify-content-between mt-4 mb-3">
        <input
          ref={fileInputRef}
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
          className="form-control w-100 w-md-50"
        />
        <button className="btn btn-secondary flex-grow-1 flex-md-grow-0" onClick={handleRefresh}>
          Refresh
        </button>
        <button className="btn btn-info flex-grow-1 flex-md-grow-0" onClick={downloadSampleCsv}>
          Download Sample CSV
        </button>
      </div>

      <div className="d-flex justify-content-end mb-3">
        <select className="form-select w-auto" onChange={(e) => setFilter(e.target.value)}>
          <option value="all">All</option>
          {/* <option value="single">Single MAC</option> */}
          <option value="multiple">Multiple MACs</option>
        </select>
      </div>

      {filteredCustomers.length > 0 && (
        <div className="text-right mb-3">
          <button className="btn btn-primary" onClick={handleRetrieveAll} disabled={loader}>
            {loader ? 'Retrieving All...' : 'Retrieve All'}
          </button>
        </div>
      )}

      {filteredCustomers.length > 0 && (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Email</th>
              <th>MAC Addresses</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredCustomers.map((customer, index) => (
              <tr key={index}>
                <td>{customer.email}</td>
                <td>
                  {Array.isArray(updatedMacs[customer.email]) && updatedMacs[customer.email].length > 0 ? (
                    updatedMacs[customer.email].length === 1 ? (
                      // For single MAC address - just display it without dropdown
                      <div>
                        {updatedMacs[customer.email][0]}
                        {/* Still show check icon if retrieved */}
                        {updatedMacs[customer.email] === 'retrieved' && <FaCheckCircle className="text-success ms-2" />}
                      </div>
                    ) : (
                      // For multiple MAC addresses - show dropdown
                      <select
                        className="form-select"
                        value={selectedMacs[customer.email] || ''}
                        onChange={(e) => {
                          const selectedValue = e.target.value; // Extract value before state update
                          setSelectedMacs((prev) => ({
                            ...prev,
                            [customer.email]: selectedValue,
                          }));
                        }}
                      >
                        <option value="">Select MAC</option>
                        {updatedMacs[customer.email].map((mac, i) => (
                          <option key={i} value={mac}>
                            {mac}
                          </option>
                        ))}
                      </select>
                    )
                  ) : (
                    updatedMacs[customer.email] || 'Pending'
                  )}
                  {updatedMacs[customer.email] === 'retrieved' && <FaCheckCircle className="text-success" />}
                </td>
                <td>
                  <button
                    className="btn btn-success"
                    onClick={() => handleRetrieve(customer.email, selectedMacs[customer.email])}
                    disabled={loader || !selectedMacs[customer.email]}
                  >
                    {loader ? 'Retrieving...' : 'Retrieve'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default RetrievedViewBody;
