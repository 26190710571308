/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PATHS from '../../../routes/urls';
import RequisitionNotification from '../../staff/shared/layouts/sidebar/manager-and-executives/RequisitionNotification';
import AssetTeamSideBarItem from '../asset-team/sidebar/AssetTeamSideBarItem';
import { AES, enc } from 'crypto-js';
import { useUser } from '../../../hooks/shared/user/useUser';

const DataAnalysisSideBar = () => {
  const userCountry = sessionStorage.getItem('country');
  const email = sessionStorage.getItem('officialEmail');
  const role_id = Number(sessionStorage.getItem('roleId'));
  const detailsState = {
    name: 'React',
    messagetoken: sessionStorage.getItem('token'),
    messagerole: sessionStorage.getItem('roleId'),
    messagedepartment: sessionStorage.getItem('departmentId'),
    secret: 'Wifirocks123',
    cipher: '',
  };
  const [details, setDetails] = useState(detailsState);
  const { name, messagetoken, messagerole, messagedepartment, secret, cipher } = details;

  const obj = {
    login: messagetoken,
    pass: messagerole,
    department: messagedepartment,
  };

  const ciph = AES.encrypt(JSON.stringify(obj), secret).toString();

//   const checkCountry = () => {
//     if (userCountry === 'GH') {
//       return (
//         <li className="s-sidebar__nav-link">
//           <Link to={PATHS.BUS_DEV_GHANA_REQUISITION} target="_self" className="d-flex">
//             <span>
//               <img src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" alt="img" />
//             </span>
//             Ghana Requisitions
//             {email === 'lydia.asogwa@ghanawifi.com' && Number(`${sessionStorage.getItem('roleId')}`) === 3 && (
//               <RequisitionNotification />
//             )}
//           </Link>
//         </li>
//       );
//     }
//     return (
//       <li className="s-sidebar__nav-link">
//         <Link to={PATHS.BUS_DEV_NIGERIA_REQUISITION} target="_self" className="d-flex">
//           <span>
//             <img src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" alt="img" />
//           </span>
//           Requisition
//           {Number(`${sessionStorage.getItem('roleId')}`) === 11 && <RequisitionNotification />}
//         </Link>
//       </li>
//     );
//   };

  return (
    <div className="s-layout__sidebar">
      <Link className="s-sidebar__trigger" to="#">
        <i className="fa fa-bars mr-3" />
      </Link>
      <nav className="s-sidebar__nav">
        <Link className="navbar-brand-logo" to="/fibre-team">
          <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
        </Link>
        <ul>
          <span className="p-0 mt-3">
            <AssetTeamSideBarItem url="/data-analysis" name="Overview" />

            <li className="s-sidebar__nav-link">
              {' '}
              <a href={`https://os.tizeti.com/main/#/overview?ciph=${ciph}`} target="_blank" className="make-bold">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
                </span>
                <small>New system (Beta v2.0)</small>
              </a>
            </li>
            <AssetTeamSideBarItem url="/data-analysis/customers" name="Customers" />
            <AssetTeamSideBarItem url="/data-analysis/customer-extension" name="Customer Extensions" />
            <AssetTeamSideBarItem url="/data-analysis/bulk-extension" name="Bulk Extension" />
            <AssetTeamSideBarItem url="/data-analysis/requisition" name="Requisition" />
          </span>
        </ul>
      </nav>
    </div>
  );
};

export default DataAnalysisSideBar;
