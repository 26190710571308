import * as departments from '../config';

export const ACCOUNTS = '3a237e10-fc54-4c0c-bf02-821356840269';
export const ADMIN = 'c3caed52-dfd5-402f-80e4-31861da8554e';
export const ADMIN_DEPARTMENT = '5cc24f2e-696a-4dec-870c-b6e6aaf499f0';
export const ASSET_TEAM = '5fda6293-d2c0-4302-b071-aece18434c23';
export const CUSTOMER_SUPPORT = '874d238d-5019-4890-81a1-09ec83d36306';
export const EXPRESS_WIFI = 'a6d0ad8a-5c0a-4fe9-8adb-f9f2fe4b87d6';
export const NEW_PRODUCTS = 'a4a0d87a-bc1f-41cb-ab97-fc9d6405b8f1';
export const NOC = '6e072411-d6eb-413d-be6c-c4b5bc812320';
export const DATA_ANALYSIS = '2d923e8b-7003-4e13-b51e-716542baec8d';
export const OPERATIONS = 'e1f5454d-660d-4a0e-8640-c90950dd97fc';
export const FIBRE = '1d4df565-1d3e-45a7-8759-e94e17ed7b59';
export const PROCUREMENT = '558a7002-4933-4d11-8e21-5b68a8b8cc01';
export const VICE_PRESIDENT_SALES = '19364ad2-acdb-4f29-94f4-ee583cfdf154';
export const RESEARCH_AND_DEVELOPMENT = '22dfe612-9f8b-4183-a75e-81a33595aa46';
export const PARTNERSHIP = '779983cf-c14a-4eb5-ab35-487e0278abd2';
export const BUSINESS_DEVELOPMENT = 'a521da84-8c18-4e22-9a49-c2aee8706e79';
export const SALES = 'aaf924bf-e16f-4ddc-843b-be5f91c8b0d2';
export const SUPER_ADMIN = 'c3caed52-dfd5-402f-80e4-31861da8554e';
export const TECHNICAL_SUPPORT = 'fbdac94e-9500-4845-b260-5d6a912bda36';
export const RETENTION = 'f101a17e-d3eb-471d-8a86-ace3ab330e4e';

export const showDepartmentName = new Map([
  [`${departments.accounts}`, 'Accounts'],
  [`${departments.admin}`, 'Admin'],
  [`${departments.ADMIN_DEPARTMENT}`, 'Admin Department'],
  [`${departments.customer_support}`, 'Customer Support'],
  [`${departments.EXPRESS_WIFI}`, 'Express Wifi'],
  [`${departments.NEW_PRODUCTS}`, 'New Products'],
  [`${departments.NOC}`, 'NOC'],
  [`${departments.DATA_ANALYSIS}`, 'Data Analysis'],
  [`${departments.operations}`, 'Field Support'],
  [`${departments.VICE_PRESIDENT_SALES}`, 'Vice President Sales'],
  [`${departments.FIBRE}`, 'Fibre Team'],
  [`${departments.PROCUREMENT}`, 'Procurement'],
  [`${departments.PARTNERSHIP}`, 'Partnership'],
  [`${departments.RESEARCH_AND_DEVELOPMENT}`, 'Research and Development'],
  [`${departments.BUSINESS_DEVELOPMENT}`, 'Business Development'],
  [`${departments.SALES}`, 'Sales'],
  [`${departments.TECHNICAL_SUPPORT}`, 'Field Support'],
  [`${departments.ASSET_TEAM}`, 'Asset Team'],
  [`${departments.RETENTION}`, 'Retention'],
]);

export const DEPARTMENT_LIST = [
  { name: 'Account', department_id: ACCOUNTS },
  { name: 'Admin', department_id: ADMIN_DEPARTMENT },
  { name: 'Asset Team', department_id: ASSET_TEAM },
  { name: 'Customer Support', department_id: CUSTOMER_SUPPORT },
  { name: 'Express Wi-Fi', department_id: EXPRESS_WIFI },
  { name: 'NOC', department_id: NOC },
  { name: 'Data Analysis', department_id: DATA_ANALYSIS },
  { name: 'Operations (FSE)', department_id: OPERATIONS },
  { name: 'Fibre Team', department_id: FIBRE },
  { name: 'Procurement', department_id: PROCUREMENT },
  { name: 'Partnership', department_id: PARTNERSHIP },
  { name: 'Research and Development', department_id: RESEARCH_AND_DEVELOPMENT },
  { name: 'Business Development', department_id: BUSINESS_DEVELOPMENT },
  { name: 'Sales', department_id: SALES },
  { name: 'Super Admin', department_id: SUPER_ADMIN },
  { name: 'Technical Support', department_id: TECHNICAL_SUPPORT },
  { name: 'Retention', department_id: RETENTION },
];
