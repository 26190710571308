/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable camelcase */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import { getUser } from '../../../../../actions/shared/user/UserActions';
import CustomerServiceScript from '../../../shared/CustomerServiceScript';
import RetentionScript from '../../../shared/customer-support/RetentionScript';
import DataAnalysisNavBar from '../../../../layouts/data-analysis /DataAnalysisNavBar';
import SupportCustomersTable from '../../../shared/customers/shared/SupportCustomersTable';

class DataAnalysisCustomers extends Component {
  componentDidMount() {
    this.props.getLoggedInUser();
  }

  render() {
    const { role } = this.props;
    const { role_id, firstName } = role;

    return (
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <DataAnalysisNavBar currentPage="Customers" roleID={role_id} position={firstName} />
          <div className="col-12 mt-5">
            <SupportCustomersTable />

            <span className="d-none d-md-flex">
              <RetentionScript />
              <CustomerServiceScript />
            </span>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.auth.auth,
  firstName: state.user.user,
});

DataAnalysisCustomers.propTypes = {
  role: PropTypes.shape(),
  getLoggedInUser: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { getLoggedInUser, getUser })(DataAnalysisCustomers);
