/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import TizetiLoader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import {
  getExtensions,
  paginateExtensions,
} from '../../../../../actions/shared/customers/extensions/CustomersExtensionsActions';

class Extensions extends Component {
  componentDidMount() {
    this.props.getExtensions();
  }

  handlePageClick = (data) => {
    const selected = data.selected + 1;

    this.props.paginateExtensions(selected);
  };

  render() {
    const { load } = this.props;

    const { logs, pages } = this.props.extensions;

    const extensionLists = logs ? (
      logs.map((extension, i) => (
        <tr key={i + 1}>
          <td>{i + 1}</td>
          {extension.EmployeeAuth && extension.EmployeeAuth.employees[0] ? (
            <td>
              {extension.EmployeeAuth.employees[0].firstName} {extension.EmployeeAuth.employees[0].lastName}
            </td>
          ) : (
            'N/A'
          )}
          <td>{extension.extending_days}</td>
          <td>{extension.macid}</td>
          {extension.name ? <td>{extension.name}</td> : <td>N/A</td>}
          <td>{extension.comment}</td>
          <td>{moment(extension.created_at).format('YYYY-MM-DD')}</td>
        </tr>
      ))
    ) : (
      <tr>
        <td>
          <p>There are no extensions</p>
        </td>
      </tr>
    );

    return (
      //  <div className="page-wrapper col-lg-10">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 mt-5">
            <div className="card x-panel">
              <div className="table-responsive content">
                <table className="table copy-font">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Employee</th>
                      <th>Days Extended</th>
                      <th>Customer Mac ID</th>
                      <th>Customer Name</th>
                      <th>Reason for Extension</th>
                      <th>Date of Extension</th>
                    </tr>
                  </thead>
                  <tbody>
                    {load ? (
                      <tr>
                        <td>
                          <img src={TizetiLoader} alt="loader" />
                        </td>
                      </tr>
                    ) : (
                      extensionLists
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <ReactPaginate
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={this.handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  extensions: state.extensions.extensions,
  load: state.extensions.loading,
});

export default connect(mapStateToProps, {
  getExtensions,
  paginateExtensions,
})(Extensions);
