/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
/* eslint-disable no-case-declarations */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { stringify } from 'qs';
import views from '../../../../../assets/img/icons/view.png';
import { useCustomer } from '../../../../../hooks/shared/customers/useCustomers';
import { usePayments } from '../../../../../hooks/shared/customers/usePayments';
import PaymentsHistory from '../../../shared/customers/payments/PaymentsHistory';
import ExtendCustomer from '../../../shared/customers/extensions/ExtendCustomer';
import ViewPassword from '../../../shared/customers/passwords/ViewPassword';
import CustomerDetails from '../../../shared/customers/shared/CustomerDetails';
import EditCustomerDetails from './EditCustomerDetails';
import edit from '../../../../../assets/img/icons/edit.png';
import add from '../../../../../assets/img/icons/add.png';
import { getLoggedInUser } from '../../../../../actions/user/UserActions';
import Rollback from '../../../shared/customers/extensions/Rollback';
import { displayCountryDropdown } from '../../../../../utils/geniatech/Geniatech';
import { useUser } from '../../../../../hooks/shared/user/useUser';
import { CUSTOMER_SUPPORT, DATA_ANALYSIS, EXPRESS_WIFI, SUPER_ADMIN } from '../../../../../utils/departments/helpers';
import { useInstallations } from '../../../../../hooks/shared/installations/useInstallations';
import AccountExtension from '../../../shared/account-extension/AccountExtension';
import { TizetiGIFLogoTable } from '../../../../../utils/loader/loadingHelper';
import AddCallLog from '../call-log/AddCallLog';
import ViewCallLog from '../call-log/ViewCallLog';
import { useCallLog } from '../../../../../hooks/customer-support/call-logs/useCallLog';
import ReactPaginate from 'react-paginate';

const SupportCustomersTable = ({ getLoggedInUser, role }) => {
  useEffect(() => {
    getLoggedInUser();
  }, [getLoggedInUser]);

  const {
    search,
    onSearchChange,
    searchForUserByEmail,
    searchByPhoneOrMac,
    searchByName,
    pageCount,
    customers,
    isLoading,
    option,
    onOptionChange,
    getPassword,
    gettingPassword,
    password,
    accounts,
    getUserDetails,
    gettingDetails,
    details,
    address,
    itemprice,
    session,
    country,
    onCountryChange,
    searchByReference,
    installationDate,
  } = useCustomer();

  const { list, load, searchUsingKeyword, pages } = useCallLog();
  const [username, setUserName] = useState('');
  const { getCalledStation, calledstation, radacct, isFetching } = useInstallations();
  const { payments, gettingPayments, getCustomerPayments } = usePayments();
  const [mac, setMac] = useState('');
  const [name, setName] = useState('');

  const [email, setEmail] = useState('');

  const [phone, setPhone] = useState('');
  const [cin, setCin] = useState('');
  const [fullCustomerDetail, setFullCustomerDetail] = useState(null);
  const [basestation, setBasestation] = useState('');
  const [zone, setZone] = useState('');
  const [show, setShow] = useState(false);
  const [view, setView] = useState(false);
  const [customerRef, setCustomerRef] = useState('');
  const [oneCustomer, setOneCustomer] = useState({});
  const { profile, user } = useUser();
  const { department_id } = user;

  const [page, setPage] = useState(1); // Ensure page state is being tracked

  const handlePageClicks = (e) => {
    const selectedPage = e.selected + 1;
    setPage(selectedPage); // Update state with new page number

    const nameSearch = {
      name: search.trim(),
      country,
      page: selectedPage,
    };

    searchByName(nameSearch);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    searchUsingKeyword(selectedPage, cin);
  };

  const searchForUser = (e) => {
    e.preventDefault();
    switch (option) {
      case 'email':
        const emailSearch = {
          email: search.trim(),
        };
        searchForUserByEmail(emailSearch);
        break;

      case 'name':
        const nameSearch = {
          name: search.trim(),
          country,
          page,
        };
        searchByName(nameSearch);
        break;

      case 'phone':
        const phoneSearch = {
          phoneNumber: search.trim(),
          macAddress: '12',
          category: 'phoneNumber',
        };
        searchByPhoneOrMac(phoneSearch);
        break;

      case 'macid':
        const macidSearch = {
          macAddress: search.trim(),
          phoneNumber: 'N/A',
          macid: search.trim(),
          category: 'macaddress',
        };
        searchByPhoneOrMac(macidSearch);
        break;

      case 'reference':
        const refernceNumberSearch = {
          reference: search.trim(),
          phoneNumber: 'N/A',
          macAddress: 'N/A',
          action: 'getUserInformationWithPhoneOrMac',
          category: 'reference',
        };
        searchByReference(refernceNumberSearch);
        break;

      default:
        return null;
    }
  };
  const getUserPassword = (customer) => {
    const macData = {
      action: 'getCustomerPassword',
      username: customer.username,
    };
    const emailData = { email: customer.mail };
    getPassword(macData, emailData);
  };
  const getCustomerDetails = (customer) => {
    setName(customer.name);
    setEmail(customer.mail);
    setZone(customer.zone);
    setBasestation(customer.basestation);
    setCustomerRef(customer.customer_ref);
    getUserDetails(customer.username);
    setFullCustomerDetail(customer);
  };
  const getUser = async (customer) => {
    setMac(customer.username);
    setName(customer.name);
    setEmail(customer.mail);
    setPhone(customer.address);
    getCalledStation(customer.username);
  };

  const addUser = async (customer) => {
    setMac(customer.username);
    setName(customer.name);
    setEmail(customer.mail);
    setPhone(customer.address);
    setCin(customer.customer_ref);
    getCalledStation(customer.username);
    setShow(true);
  };

  const viewUser = async (customer) => {
    setCin(customer.customer_ref);

    searchUsingKeyword(page, customer.customer_ref);
    setView(true);
  };
  const getUserPayments = (customer) => {
    setOneCustomer(customer);
    const date = new Date();
    const sevenDaysAgo = new Date(date.getTime() - 120 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10);
    const today = new Date().toISOString().slice(0, 10);
    const data = {
      macid: customer.username,
      action: 'getDebtorTransPaginated',
      totalRec: '10',
      page_no: '1',
      from: sevenDaysAgo,
      to: today,
      country: customer.country || 'Nigeria',
    };
    getCustomerPayments(stringify(data));
  };
  const searchWithDate = (data) => {
    getCustomerPayments(data);
  };

  const getUserForRollback = (c) => {
    // mac
    setName(c.name);
    // name
    setUserName(c.username);
  };
  const { role_id } = role;
  const unallowedEmails = [
    'anita.kings@tizeti.com',
    'christabel.okosun@tizeti.com',
    'chukwuemeka.udom@tizeti.com',
    'racheal.osoluka@tizeti.com',
    'emmanuel.eyo@tizeti.com',
  ];
  const hideExtendCustomer = (custo) => {
    if (
      Number(role_id) !== 6 &&
      (department_id === `${CUSTOMER_SUPPORT}` ||
        department_id === `${DATA_ANALYSIS}` ||
        department_id === `${SUPER_ADMIN}`) &&
      !unallowedEmails.includes(profile.officialEmail)
    ) {
      return (
        <td className="text-center">
          <button
            type="submit"
            data-target="#extend"
            data-toggle="modal"
            className="noBorder"
            onClick={() => getUser(custo)}
          >
            <span className="view">
              <img src={views} data-toggle="tooltip" alt="Extend" title="Extend" />
            </span>
          </button>
        </td>
      );
    }
    return null;
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-flex justify-content-between mt-5">
            <div className="">
              <form onSubmit={searchForUser} className="form-inline inner-addon d-flex">
                <div className="input-group mb-3 mr-3">
                  <select name="keyword" onChange={onOptionChange} className="form-control mr-auto empty mt-1">
                    <option value="">Choose fields to search from</option>
                    <option value="email">Email</option>
                    <option value="name">Name</option>
                    <option value="phone">Phone</option>
                    <option value="macid">MAC ID</option>
                    <option value="reference">Account Number</option>
                  </select>
                </div>

                {displayCountryDropdown(option, onCountryChange)}

                <div className="input-group mb-3 mr-3">
                  <input
                    className="form-control mr-auto empty pl-5"
                    type="text"
                    placeholder="search for user"
                    name="search"
                    value={search}
                    onChange={onSearchChange}
                  />
                  <span className="fa fa-search" />
                </div>

                <div className="mb-3">
                  <button className="btn btn-green" style={{ height: '39px' }}>
                    Search
                  </button>
                </div>
              </form>
              {department_id === `${CUSTOMER_SUPPORT}` || department_id === `${SUPER_ADMIN}` ? (
                <div className="ml-2">
                  <Link to="/customer-support/customer/payment-history" target="_self">
                    <button type="button" className="btn btn-primary">
                      Payment History
                    </button>
                  </Link>
                </div>
              ) : null}
            </div>

            <div className="pr-3">
              <AccountExtension />
            </div>
          </div>
          <div className="card x-panel m-5">
            <div className="table-responsive content">
              <table className="table copy-font">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>MAC ID</th>
                    <th nowrap="no-wrap">Name</th>
                    <th>Email</th>
                    {department_id === `${CUSTOMER_SUPPORT}` || department_id === `${SUPER_ADMIN}` ? (
                      <th>Show Password</th>
                    ) : null}
                    <th nowrap="no-wrap">Phone Number</th>
                    {Number(role_id) !== 6 &&
                    profile.officialEmail !== 'sandra.osimen@tizeti.com' &&
                    (department_id === `${CUSTOMER_SUPPORT}` ||
                      department_id === `${DATA_ANALYSIS}` ||
                      department_id === `${SUPER_ADMIN}`) &&
                    !unallowedEmails.includes(profile.officialEmail) ? (
                      <th className="text-center">Extend</th>
                    ) : null}
                    {/* {Number(role_id) > 1 &&
    (department_id === `${CUSTOMER_SUPPORT}` || department_id === `${SUPER_ADMIN}`) ? (
      <th className="text-center">Rollback</th>
    ) : null} */}
                    <th nowrap="no-wrap">Expiry Date</th>
                    {(profile &&
                      profile.officialEmail &&
                      ['ademayowa.adelowo@tizeti.com', 'alexander.omenigbo@tizeti.com'].includes(
                        profile.officialEmail.toLowerCase(),
                      )) ||
                    department_id === `${EXPRESS_WIFI}` ||
                    Number(role_id) > 1 ? (
                      <th className="text-center">Edit</th>
                    ) : null}
                    {department_id === `${CUSTOMER_SUPPORT}` || department_id === `${SUPER_ADMIN}` ? (
                      <>
                        <th className="text-center">Add Call Log</th>
                        <th className="text-center">View Call Log</th>
                      </>
                    ) : null}
                    {department_id === `${CUSTOMER_SUPPORT}` || department_id === `${SUPER_ADMIN}` ? (
                      <th style={{ paddingLeft: '24px' }}>Actions</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <TizetiGIFLogoTable colSpan="13" />
                  ) : (
                    customers.map((customer, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{customer.username}</td>
                          <td nowrap="no-wrap">{customer.name}</td>
                          <td>{customer.mail}</td>
                          {department_id === `${CUSTOMER_SUPPORT}` || department_id === `${SUPER_ADMIN}` ? (
                            <td className="ml-2">
                              <button
                                data-toggle="modal"
                                data-target="#view-password"
                                className="ml-3 noBorder"
                                onClick={() => getUserPassword(customer)}
                              >
                                <i className="view1 fa fa-ellipsis-v" style={{ fontSize: '20px' }} />
                              </button>
                              <ViewPassword password={password} accounts={accounts} load={gettingPassword} />
                            </td>
                          ) : null}
                          <td>{customer.address}</td>
                          {hideExtendCustomer(customer)}
                          <ExtendCustomer username={mac} name={name} />
                          {/* {Number(role_id) > 1 &&
                    (department_id === `${CUSTOMER_SUPPORT}` || department_id === `${SUPER_ADMIN}`) ? (
                      <td className="ml-2">
                        <button
                          data-toggle="modal"
                          data-target="#rollback"
                          className="ml-3 noBorder"
                          onClick={() => getUserForRollback(customer)}
                        >
                          <i className="view1 fa fa-ellipsis-v" style={{ fontSize: '20px' }} />
                        </button>
                        <Rollback username={username} name={name} />
                      </td>
                    ) : null} */}
                          <td>{customer.value}</td>
                          {(profile &&
                            profile.officialEmail &&
                            ['ademayowa.adelowo@tizeti.com', 'alexander.omenigbo@tizeti.com'].includes(
                              profile.officialEmail.toLowerCase(),
                            )) ||
                          department_id === `${EXPRESS_WIFI}` ||
                          (Number(role_id) > 1 && Number(role_id) !== 6) ? (
                            <>
                              <td
                                className="text-center img-pointer noBorder"
                                data-target="#edit-details"
                                onClick={() => getUser(customer)}
                                data-toggle="modal"
                                style={{ width: '40%' }}
                              >
                                <span className="view2">
                                  <img src={edit} alt="" data-toggle="tooltip" data-placement="top" title="Edit" />
                                </span>
                              </td>
                            </>
                          ) : null}
                          {(department_id === `${CUSTOMER_SUPPORT}` || department_id === `${SUPER_ADMIN}`) && (
                            <>
                              <td
                                className="text-center img-pointer noBorder"
                                onClick={() => addUser(customer)}
                                style={{ width: '40%' }}
                              >
                                <span className="view2">
                                  <img src={add} alt="" data-toggle="tooltip" data-placement="top" title="Add" />
                                </span>
                              </td>
                              <td
                                className="text-center img-pointer noBorder"
                                onClick={() => viewUser(customer)}
                                style={{ width: '40%' }}
                              >
                                <span className="view">
                                  <img src={views} alt="" data-toggle="tooltip" data-placement="top" title="View" />
                                </span>
                              </td>
                            </>
                          )}

                          <AddCallLog
                            show={show}
                            oldEmail={email}
                            oldName={name}
                            oldPhone={phone}
                            cin={cin}
                            setShow={setShow}
                          />
                          <ViewCallLog
                            show={view}
                            cin={cin}
                            setShow={setView}
                            handlePageClick={handlePageClick}
                            list={list}
                            pages={pages}
                            load={load}
                          />
                          {calledstation ? (
                            <EditCustomerDetails
                              username={mac}
                              oldEmail={email}
                              oldName={name}
                              oldPhone={phone}
                              calledstation={calledstation}
                              radacct={radacct}
                              isFetching={isFetching}
                              item={{}}
                            />
                          ) : null}

                          {department_id === `${CUSTOMER_SUPPORT}` || department_id === `${SUPER_ADMIN}` ? (
                            <td className="ml-2">
                              <button
                                data-toggle="modal"
                                data-target="#payment-history"
                                className="ml-3 noBorder"
                                onClick={() => getUserPayments(customer)}
                              >
                                <i className="view1 fa fa-ellipsis-v" style={{ fontSize: '20px' }} />
                              </button>

                              <PaymentsHistory
                                customer={oneCustomer}
                                load={gettingPayments}
                                transactions={payments}
                                searchWithDate={searchWithDate}
                              />

                              <button
                                type="submit"
                                style={{
                                  border: '0',
                                  backgroundColor: 'white',
                                  width: '0%',
                                }}
                                data-toggle="tooltip"
                                data-placement="left"
                                title="View"
                                onClick={() => getCustomerDetails(customer)}
                              >
                                <span className="view1 ml-4">
                                  <img src={views} alt="" data-toggle="modal" data-target="#cus-details" />
                                </span>
                              </button>
                              <CustomerDetails
                                load={gettingDetails}
                                user={fullCustomerDetail}
                                name={name}
                                mail={email}
                                customerRef={customerRef}
                                itemprice={itemprice}
                                address={address}
                                details={details}
                                session={session}
                                zone={zone}
                                basestation={basestation}
                                installationDate={installationDate}
                              />
                            </td>
                          ) : null}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* Pagination Section */}
        {pageCount > 1 && (
          <div className="pagination-container my-3">
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              breakLabel={'...'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClicks}
              containerClassName={'pagination'}
              activeClassName={'active'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'prev-item'}
              previousLinkClassName={'prev-link'}
              nextClassName={'next-item'}
              nextLinkClassName={'next-link'}
              breakClassName={'break-item'}
              breakLinkClassName={'break-link'}
            />
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  role: state.auth.auth,
});

SupportCustomersTable.propTypes = {
  role: PropTypes.shape(),
  getLoggedInUser: PropTypes.func,
};

export default connect(mapStateToProps, { getLoggedInUser })(SupportCustomersTable);
