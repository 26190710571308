/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import '../../../../../styles/basestation.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import mail from '../../../../../assets/img/icons/mailblast.svg';
import CustomerSupportSideBar from '../../../../layouts/customer-support/CustomerSupportSideBar';
import Logout from '../../../shared/auth/Logout';

const ExtensionLogReportIndex = () => {
  const [logs, setLogs] = useState([]);
  const [summary, setSummary] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showDetails, setShowDetails] = useState(false);
  const [dateRange, setDateRange] = useState({
    from: new Date().toISOString().split('T')[0],
    to: new Date().toISOString().split('T')[0],
  });

  const fetchLogs = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `https://api.tizeti.com/api/v1/geniatechlog/get_extension_logs/${currentPage}`,
        {
          from: dateRange.from,
          to: dateRange.to,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setLogs(response.data.logs);
      setTotalPages(response.data.pages);
      setShowDetails(true);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  };

  const fetchSummary = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        'https://api.tizeti.com/api/v1/geniatechlog/logReport',
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setSummary(response.data);
    } catch (error) {
      console.error('Error fetching summary:', error);
    }
  };

  const handleDateSearch = (e) => {
    e.preventDefault();
    fetchLogs();
  };

  const handleReset = () => {
    setShowDetails(false);
    setDateRange({
      from: new Date().toISOString().split('T')[0],
      to: new Date().toISOString().split('T')[0],
    });
    fetchSummary();
  };

  useEffect(() => {
    fetchSummary();
  }, []);

  useEffect(() => {
    if (showDetails) {
      fetchLogs();
    }
  }, [currentPage]);

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <div className="d-flex col-lg-12 p-2 top-header">
          <div className="mt-3 d-flex col-lg-12 p-0">
            <Link className="s-sidebar__trigger" to="#">
              <i className="fa fa-bars mr-3" />
            </Link>
            <CustomerSupportSideBar />

            <h3 className="col-lg-5 overview-part">Customer Extension Reports</h3>

            <div className="ml-5 d-flex profile-mail pr-5">
              <Link
                to="/customer-support/mail-blast"
                className="d-none d-md-flex"
                style={{ color: 'gray', textDecoration: 'none' }}
              >
                <img src={mail} alt="" className="logout mr-2" />
                <span>
                  <h6 className="m-2">
                    <strong>MailBlast</strong>
                  </h6>
                </span>
              </Link>

              <h6 className="mr-0 d-flex">
                {/* <i className="small mr-2 d-none d-md-block">{firstName}</i> */}
                <Logout />
              </h6>
            </div>
          </div>
        </div>

        <div className="after-summary">
          <div className="container-fluid p-4">
            {/* <div className="card mb-4">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0">Date Range Selection</h5>
                  </div>
                  <div className="col-auto d-flex align-items-center">
                    <input
                      type="date"
                      className="form-control mr-2"
                      value={dateRange.from}
                      onChange={(e) => setDateRange({ ...dateRange, from: e.target.value })}
                    />
                    <input
                      type="date"
                      className="form-control mr-2"
                      value={dateRange.to}
                      onChange={(e) => setDateRange({ ...dateRange, to: e.target.value })}
                    />
                    <button className="btn btn-primary mr-2" onClick={handleDateSearch}>
                      Search
                    </button>
                    {showDetails && (
                      <button className="btn btn-secondary" onClick={handleReset}>
                        Back to Summary
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div> */}

            {!showDetails && summary && (
              <div className="row">
                {summary.map((item, index) => (
                  <div key={index} className="col-md-3 mb-4">
                    <div className="card h-100">
                      <div className="card-body">
                        <h6 className="card-title mb-3">Summary for {item.date}</h6>
                        <div className="d-flex flex-column">
                          <div className="mb-2">
                            <small className="text-muted">Total Days Compensated</small>
                            <p className="mb-0 font-weight-bold">{item.total_days_compensated}</p>
                          </div>
                          <div>
                            <small className="text-muted">MAC IDs Count</small>
                            <p className="mb-0 font-weight-bold">{item.macids_count}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {showDetails && (
              <div className="card">
                <div className="card-body">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>MAC ID</th>
                        <th>Days Extended</th>
                        <th>Staff</th>
                        <th>Comment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {logs.map((log) => (
                        <tr key={log.id}>
                          <td>{new Date(log.created_at).toLocaleDateString()}</td>
                          <td>{log.macid}</td>
                          <td>{log.extending_days}</td>
                          <td>{`${log.EmployeeAuth.employees[0].firstName} ${log.EmployeeAuth.employees[0].lastName}`}</td>
                          <td>{log.comment}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="d-flex justify-content-center mt-4">
                    <nav className="d-flex">
                      <ul className="pagination flex flex-row m-0" style={{ display: 'flex' }}>
                        <li className="page-item">
                          <button
                            className="page-link"
                            onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
                            disabled={currentPage === 1}
                          >
                            Previous
                          </button>
                        </li>
                        {[...Array(totalPages)].map((_, index) => (
                          <li
                            key={index}
                            className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                            style={{ display: 'flex' }}
                          >
                            <button className="page-link" onClick={() => setCurrentPage(index + 1)}>
                              {index + 1}
                            </button>
                          </li>
                        ))}
                        <li className="page-item">
                          <button
                            className="page-link"
                            onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}
                            disabled={currentPage === totalPages}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ExtensionLogReportIndex;
