/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useInstallations } from '../../../../hooks/shared/installations/useInstallations';
import { useUserProfile } from '../../../../hooks/shared/user/useUserProfile';

const CalledStation = ({ macid, calledstation, radacct, isFetching }) => {
  const { profile, getUserProfile } = useUserProfile();
  const { createCalledStation, dimension1, dimension2, fetching, getDimension2 } = useInstallations();

  const dimensionState = {
    reason: '',
    dimension1_id: '',
    dimension2_id: '',
    dimension2_name: '',
    country: '',
  };

  const [dimensions, setDimensions] = useState(dimensionState);
  const { reason, dimension1_id, dimension2_id, dimension2_name, country} = dimensions;

  useEffect(() => {
    getUserProfile();
  }, []);

  const editCalledStation = (e) => {
    e.preventDefault();
    const data1 = { macid, calledstation: dimension2_name };
    const data2 = {
      // action: 'updateDimensions',
      username: macid,
      country:country,
      dimension1: dimension1_id,
      dimension2: dimension2_id,
      staff_email: profile.email,
      reason,
    };
    createCalledStation(data1, data2);
  };

  const handleChange = (e) => {
    const { name, value, selectedOptions } = e.target;
    if (name === 'dimension1_id') {
      getDimension2(selectedOptions[0].getAttribute('name'));
      setDimensions({ ...dimensions, dimension1_id: value, dimension2_id: '', dimension2_name: '' });
    } else if (name === 'dimension2_id') {
      setDimensions({ ...dimensions, [name]: value, dimension2_name: selectedOptions[0].getAttribute('name') });
    } else setDimensions({ ...dimensions, [name]: value });
  };

  const submitForm = () =>
    (calledstation.length >= 1 || radacct.length >= 1) && dimension2_name.length >= 1 && reason.length >= 5;

  return (
    <div className="py-3">
      <form className="form-horizontal" onSubmit={editCalledStation}>
        <h3 className="text-center">Call Station</h3>
        <div className="form-group">
          <div className="col-12 mb-3 d-flex">
            <div className="col-6">
              {isFetching ? (
                <input type="text" className="form-control" value="Loading..." readOnly />
              ) : calledstation.length >= 1 ? (
                calledstation.map((station) => (
                  <input type="text" className="form-control" value={station.calledstation.toUpperCase()} readOnly />
                ))
              ) : radacct.length >= 1 ? (
                radacct.map((station) => (
                  <input type="text" className="form-control" value={station.calledstationid.toUpperCase()} readOnly />
                ))
              ) : (
                <input type="text" className="form-control" value="Not Found" readOnly />
              )}
            </div>
            <div className="col-6">
              <select name="dimension1_id" className="form-control" onChange={handleChange}>
                <option value="">--Select Dimension1--</option>
                {dimension1.length ? (
                  dimension1.map((item) => {
                    return (
                      <option key={item.id} value={item.id} name={item.name}>
                        {item.name.toUpperCase()}
                      </option>
                    );
                  })
                ) : (
                  <option value="">Fetching Dimension1...</option>
                )}
              </select>
            </div>
          </div>

          <div className="col-12 mb-3 d-flex">
            <div className="col-6">
              {fetching ? (
                <input type="text" className="form-control" value="LOADING..." disabled />
              ) : dimension2.length === 0 ? (
                <input type="text" className="form-control" value="SELECT DIMENSION 1 FIRST" disabled />
              ) : dimension2 === 'EMPTY' ? (
                <input type="text" className="form-control" value={dimension2} disabled />
              ) : (
                <select name="dimension2_id" className="form-control" onChange={handleChange}>
                  <option value="">--Select Dimension2--</option>
                  {dimension2.map((item) => {
                    return (
                      <option key={item.id} value={item.id} name={item.name}>
                        {item.label.toUpperCase()}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
            <div className="col-6">
              <select name="country" className="form-control" onChange={handleChange}>
                <option value="">--Select Country--</option>
                <option value="ng">Nigeria</option>
                <option value="gh">Ghana</option>
              </select>
            </div>
          </div>

          <div className="col-12 mb-3">
            <div className="col-12">
              <textarea
                placeholder="Reason"
                className="form-control shadow-sm borderRadius0"
                name="reason"
                value={reason}
                onChange={handleChange}
              />
            </div>
          </div>

          {isFetching ? (
            <div className="col-12 mb-3 text-center">
              <button type="button" className="btn btn-green" disabled>
                Editing...
              </button>
            </div>
          ) : (
            <div className="col-12 mb-3 text-center">
              <button type="submit" className="btn btn-green">
                Edit CalledStation 
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default CalledStation;
