/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUserProfile } from '../../../hooks/shared/user/useUserProfile';
import PATHS from '../../../routes/urls';
import RequisitionNotification from '../../staff/shared/layouts/sidebar/manager-and-executives/RequisitionNotification';
import AssetTeamSideBarItem from '../asset-team/sidebar/AssetTeamSideBarItem';
import { AES, enc } from 'crypto-js';
import { useUser } from '../../../hooks/shared/user/useUser';

const PartnershipSideBar = () => {
  const { getUserProfile, profile } = useUserProfile();
  const { user, profile: prof } = useUser();
  // console.log(profile);

  const role_id = Number(sessionStorage.getItem('roleId'));

  useEffect(() => {
    getUserProfile();
  }, []);

  const detailsState = {
    name: 'React',
    messagetoken: sessionStorage.getItem('token'),
    messagerole: sessionStorage.getItem('roleId'),
    messagedepartment: sessionStorage.getItem('departmentId'),
    secret: 'Wifirocks123',
    cipher: '',
  };
  const [details, setDetails] = useState(detailsState);
  const { name, messagetoken, messagerole, messagedepartment, secret, cipher } = details;

  const obj = {
    login: messagetoken,
    pass: messagerole,
    department: messagedepartment,
  };

  const ciph = AES.encrypt(JSON.stringify(obj), secret).toString();

  return (
    <div className="s-layout__sidebar">
      <Link className="s-sidebar__trigger" to="#">
        <i className="fa fa-bars mr-3" />
      </Link>
      <nav className="s-sidebar__nav">
        <Link className="navbar-brand-logo" to="/business-development">
          <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
        </Link>
        <ul>
          <li className="s-sidebar__nav-link">
            <span className="p-0 mt-3">
              <AssetTeamSideBarItem url="/partnership" name="Overview" />
            </span>
          </li>
          <li className="s-sidebar__nav-link">
            <a href={`https://os.tizeti.com/main/#/overview?ciph=${ciph}`} target="_blank" className="make-bold">
              <span>
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
              </span>
              <small>New system (Beta v2.0)</small>
            </a>
          </li>

          <li>
            <span className="p-0 mt-3">
              <AssetTeamSideBarItem url="/logs" name="Logs" />
            </span>
          </li>
    
          <li>
            <span className="p-0 mt-3">
              <AssetTeamSideBarItem url="/active-and-inactive" name="Active And Inactive Customers" />
            </span>
          </li>
         
          <li>
            <span className="p-0 mt-3">
              <AssetTeamSideBarItem url="/partnership-requisitions" name="Requistions" />
            </span>
          </li>
         
         
        </ul>
      </nav>
    </div>
  );
};

export default PartnershipSideBar;
