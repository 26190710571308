import { API_URL } from '../../../utils/config';

export const SEARCH_WIFICALL_CUSTOMERS_WITH_EMAIL_URL = `${API_URL}/wificall/supportemailsearch`;
export const SEARCH_WIFICALL_CUSTOMERS_WITH_PHONE_URL = `${API_URL}/wificall/supportphonesearch`;
export const GET_UNVERIFIED_CUSTOMER_URL = `${API_URL}/wificall/unverified`;
export const GET_VERIFIED_CUSTOMER_URL = `${API_URL}/wificall/verified`;
export const SEARCH_UNVERIFIED_WIFICALL_CUSTOMERS_WITH_DATE_URL = `${API_URL}/wificall/admin/unverifiedrange/1`;
export const VERIFY_USER_URL = `${API_URL}/wificall/verification`;
export const GET_CUSTOMER_PAYMENTS_URL = `${API_URL}/sourcelist`;
export const SEND_PUSH_NOTIFICATION_URL = `https://legacy.tizeti.com/geniatechapi/index.php`;
export const WIFICALL_PLANS_URL = `${API_URL}/planlist`;
export const VERIFY_WIFICALL_USER_BY_USER_ID = `${API_URL}/wificall/support`;
export const FILTER_UNVERIFIED_USERS_URL = `${API_URL}/wificall/admin/unverifiedrange`;
export const FILTER_LOGIN_CODE_URL = `${API_URL}/wificall/logincodelog`;
export const FREE_SIGNUPS_URL = `${API_URL}/campaign`;
