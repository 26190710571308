import React from 'react';
import PropTypes from 'prop-types';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { NoItemRow } from '../../../../../utils/loader/loadingHelper';

const CustomerSupportVoiceMailTable = ({ items, isLoading }) => {
  const tableData =
    items && items.length ? (
      items.map((item, i) => {
        const { calldate, cnum, duration, lastapp, linkedid, src, uniqueid, url } = item;
        // console.log("🚀 ~ items.map ~ url:", url)

        const formattedDate = new Date(calldate).toLocaleString();

        return (
          <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{formattedDate}</td>
            <td>{cnum}</td>
            <td>{src}</td>
            <td>{duration} sec</td>
            <td>{lastapp}</td>
            <td>
              {/* Playable Audio */}
              <audio controls preload="none">
                {url && <source src={url} type="audio/wav" />}
              </audio>
            </td>
            <td>{uniqueid}</td>
          </tr>
        );
      })
    ) : (
      <NoItemRow colSpan="8" text="There Are No Voicemail Records" />
    );

  return (
    <div className="card x-panel mt-2 px-0">
      <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Call Date</th>
              <th>Caller Number</th>
              <th>Source</th>
              <th>Duration</th>
              <th>Last App</th>
              <th>Playback</th>
              <th>Unique ID</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="text-center" colSpan="8">
                  <img src={loader} alt="loading gif" />
                </td>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

CustomerSupportVoiceMailTable.propTypes = {
  items: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default CustomerSupportVoiceMailTable;
