/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PATHS from '../../../routes/urls';
import RequisitionNotification from '../../staff/shared/layouts/sidebar/manager-and-executives/RequisitionNotification';
import AssetTeamSideBarItem from '../asset-team/sidebar/AssetTeamSideBarItem';
import { AES, enc } from 'crypto-js';
import { useUser } from '../../../hooks/shared/user/useUser';
import FieldSupportSideBarItem from '../field-support/side-bar/FieldSupportSideBarItem';

const FibreSideBar = () => {
  const userCountry = sessionStorage.getItem('country');
  const email = sessionStorage.getItem('officialEmail');
  const roleId = Number(sessionStorage.getItem('roleId'));
  const role_id = Number(sessionStorage.getItem('roleId'));
  const detailsState = {
    name: 'React',
    messagetoken: sessionStorage.getItem('token'),
    messagerole: sessionStorage.getItem('roleId'),
    messagedepartment: sessionStorage.getItem('departmentId'),
    secret: 'Wifirocks123',
    cipher: '',
  };
  const [details, setDetails] = useState(detailsState);
  const { name, messagetoken, messagerole, messagedepartment, secret, cipher } = details;

  const obj = {
    login: messagetoken,
    pass: messagerole,
    department: messagedepartment,
  };

  const ciph = AES.encrypt(JSON.stringify(obj), secret).toString();

  const checkCountry = () => {
    if (userCountry === 'GH') {
      return (
        <li className="s-sidebar__nav-link">
          <Link to={PATHS.BUS_DEV_GHANA_REQUISITION} target="_self" className="d-flex">
            <span>
              <img src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" alt="img" />
            </span>
            Ghana Requisitions
            {email === 'lydia.asogwa@ghanawifi.com' && Number(`${sessionStorage.getItem('roleId')}`) === 3 && (
              <RequisitionNotification />
            )}
          </Link>
        </li>
      );
    }
    return (
      <li className="s-sidebar__nav-link">
        <Link to={PATHS.BUS_DEV_NIGERIA_REQUISITION} target="_self" className="d-flex">
          <span>
            <img src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" alt="img" />
          </span>
          Requisition
          {Number(`${sessionStorage.getItem('roleId')}`) === 11 && <RequisitionNotification />}
        </Link>
      </li>
    );
  };

  return (
    <div className="s-layout__sidebar">
      <Link className="s-sidebar__trigger" to="#">
        <i className="fa fa-bars mr-3" />
      </Link>
      <nav className="s-sidebar__nav">
        <Link className="navbar-brand-logo" to="/fibre-team">
          <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
        </Link>
        <ul>
          <span className="p-0 mt-3">
            <AssetTeamSideBarItem url="/fibre-team" name="Overview" />
            <AssetTeamSideBarItem url="/fibre-requisitions" name="Requisitions" />

            <li className="s-sidebar__nav-link">
              {' '}
              <a href={`https://os.tizeti.com/main/#/overview?ciph=${ciph}`} target="_blank" className="make-bold">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
                </span>
                <small>New system (Beta v2.0)</small>
              </a>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/teams" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png" alt="img" />
                </span>
                <small>Teams</small>
              </Link>
            </li>

            {/* <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/field-support/customers" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                <small>Customers</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/field-support/customers-downtime" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                <small>Customers Downtime</small>
              </Link>
            </li> */}

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/device-type" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                <small>Device Types</small>
              </Link>
            </li>
            {/* {roleId > 1 ? (
              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/field-support/customer-details" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                  </span>{' '}
                  Customer Details
                </Link>
              </li>
            ) : (
              ''
            )} */}

            {roleId === 3 ? (
              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/shared/installation-feedback" target="_self">
                  <span>
                    <img
                      src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png"
                      alt="img"
                    />
                  </span>{' '}
                  Installation Feedback
                </Link>
              </li>
            ) : (
              ''
            )}
            <FieldSupportSideBarItem url={PATHS.FIELD_SUPPORT_INSTALLATION_OVERVIEW} name="Installations" />
            <FieldSupportSideBarItem url="/installations/reschedule" name="Rescheduled Installations" />

            <FieldSupportSideBarItem url={PATHS.FIELD_SUPPORT_INSTALLATION_SUB_ZONES} name="Installation Sub Zones" />

            <FieldSupportSideBarItem url={PATHS.FIELD_SUPPORT_INSTALLATIONS_COUNTER} name="Installations Summary" />

            <FieldSupportSideBarItem url={PATHS.FIELD_SUPPORT_DEVICE_RETRIEVAL} name="Retrievals" />

            {Number(`${sessionStorage.getItem('roleId')}`) === 3 && (
              <li className="s-sidebar__nav-link">
                <Link to={PATHS.FIELD_SUPPORT_REFUND_LIST} target="_self">
                  <span>
                    <img
                      src="https://res.cloudinary.com/teewhy/image/upload/v1549268125/Installationzone.fw.png"
                      alt="img"
                    />
                  </span>
                  <small>Refunds</small>
                </Link>
              </li>
            )}
            <li className="s-sidebar__nav-link">
              <Link to={PATHS.ACCOUNTS_REFUNDS_ANALYTICS} target="_self">
                <span>
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1549268125/Installationzone.fw.png"
                    alt="img"
                  />
                </span>
                <small>Refunds Analytics</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to={PATHS.FIELD_SUPPORT_EMPLOYEES_INSTALLATIONS} target="_self">
                <span>
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1549268125/Installationzone.fw.png"
                    alt="img"
                  />
                </span>
                <small>Employees Installations</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/shared/basestations" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
                </span>
                <small>All Basestations</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/field-support/basestations" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
                </span>
                <small>BaseStations</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/field-support/leads" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971011/Group.png" alt="img" />
                </span>
                <small>Leads</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/teams" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png" alt="img" />
                </span>
                <small>Teams</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/field-support/customers" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                <small>Customers</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/field-support/customers-downtime" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                <small>Customers Downtime</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/device-type" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                <small>Device Types</small>
              </Link>
            </li>
            {roleId > 1 ? (
              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/field-support/customer-details" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                  </span>{' '}
                  Customer Details
                </Link>
              </li>
            ) : (
              ''
            )}

            {roleId === 3 ? (
              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/shared/installation-feedback" target="_self">
                  <span>
                    <img
                      src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png"
                      alt="img"
                    />
                  </span>{' '}
                  Installation Feedback
                </Link>
              </li>
            ) : (
              ''
            )}
            <FieldSupportSideBarItem url={PATHS.FIELD_SUPPORT_INSTALLATION_OVERVIEW} name="Installations" />
            <FieldSupportSideBarItem url="/installations/reschedule" name="Rescheduled Installations" />

            <FieldSupportSideBarItem url={PATHS.FIELD_SUPPORT_INSTALLATION_SUB_ZONES} name="Installation Sub Zones" />

            <FieldSupportSideBarItem url={PATHS.FIELD_SUPPORT_INSTALLATIONS_COUNTER} name="Installations Summary" />

            <FieldSupportSideBarItem url={PATHS.FIELD_SUPPORT_DEVICE_RETRIEVAL} name="Retrievals" />

            {Number(`${sessionStorage.getItem('roleId')}`) === 3 && (
              <li className="s-sidebar__nav-link">
                <Link to={PATHS.FIELD_SUPPORT_REFUND_LIST} target="_self">
                  <span>
                    <img
                      src="https://res.cloudinary.com/teewhy/image/upload/v1549268125/Installationzone.fw.png"
                      alt="img"
                    />
                  </span>
                  <small>Refunds</small>
                </Link>
              </li>
            )}
            <li className="s-sidebar__nav-link">
              <Link to={PATHS.ACCOUNTS_REFUNDS_ANALYTICS} target="_self">
                <span>
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1549268125/Installationzone.fw.png"
                    alt="img"
                  />
                </span>
                <small>Refunds Analytics</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to={PATHS.FIELD_SUPPORT_EMPLOYEES_INSTALLATIONS} target="_self">
                <span>
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1549268125/Installationzone.fw.png"
                    alt="img"
                  />
                </span>
                <small>Employees Installations</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/shared/basestations" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
                </span>
                <small>All Basestations</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/field-support/basestations" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
                </span>
                <small>BaseStations</small>
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/field-support/leads" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971011/Group.png" alt="img" />
                </span>
                <small>Leads</small>
              </Link>
            </li>

            {/* {checkCountry()} */}
          </span>
        </ul>
      </nav>
    </div>
  );
};

export default FibreSideBar;
