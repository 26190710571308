/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PATHS from '../../../routes/urls';
import { AES } from 'crypto-js';
import { BudgetSideBar } from '../../staff/shared/layouts/sidebar/manager-and-executives/BudgetSideBar';
import RequisitionNotification from '../../staff/shared/layouts/sidebar/manager-and-executives/RequisitionNotification';
import { CUSTOMER_SUPPORT, RETENTION, SUPER_ADMIN } from '../../../utils/departments/helpers';
import RetentionSideBarItem from './sidebar/RetentionSideBarItem';
import { useCheckThreeDepartments } from '../../../hooks/shared/custom/check-department/useCheckTwoDepartments';

const span13 = (name) => <span style={{ fontSize: '13px' }}>{name}</span>;

const RetentionSideBar = () => {
  const history = useHistory();

  const email = sessionStorage.getItem('officialEmail');

  const role_id = sessionStorage.getItem('roleId');
  const detailsState = {
    name: 'React',
    messagetoken: sessionStorage.getItem('token'),
    messagerole: sessionStorage.getItem('roleId'),
    messagedepartment: sessionStorage.getItem('departmentId'),
    secret: 'Wifirocks123',
    cipher: '',
  };
  const [details, setDetails] = useState(detailsState);
  const { name, messagetoken, messagerole, messagedepartment, secret, cipher } = details;

  const obj = {
    login: messagetoken,
    pass: messagerole,
    department: messagedepartment,
  };

  const ciph = AES.encrypt(JSON.stringify(obj), secret).toString();

  // useCheckTwoDepartments(`${CUSTOMER_SUPPORT}`, `${SUPER_ADMIN}`, history);
  useCheckThreeDepartments(`${CUSTOMER_SUPPORT}`, `${SUPER_ADMIN}`, `${RETENTION}`, history);
  const checkCountry = () => {
    if (email === 'lydia.asogwa@ghanawifi.com' && Number(`${sessionStorage.getItem('roleId')}`) === 3) {
      return (
        <li className="mt-5 s-sidebar__nav-link">
          <Link to={PATHS.CUSTOMER_SUPPORT_GHANA_CUSTOMER_REQUISITIONS} target="_self" className="d-flex">
            <span>
              <img src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" alt="img" />
            </span>
            Ghana Requisitions
            <RequisitionNotification />
          </Link>
        </li>
      );
    }
    if (Number(`${sessionStorage.getItem('roleId')}`) === 3) {
      return (
        <li className="mt-5 s-sidebar__nav-link">
          <Link to="/customer-support/requisition" target="_self" className="d-flex">
            <span className="">
              <img src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" alt="img" />
            </span>
            Requisition
            <RequisitionNotification />
          </Link>
        </li>
      );
    }
    return (
      <li className="s-sidebar__nav-link">
        <Link to="/customer-support/requisition" target="_self" className="d-flex">
          <span>
            <img src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" alt="img" />
          </span>{' '}
          Requisition
        </Link>
      </li>
    );
  };

  return (
    <div className="s-layout__sidebar">
      <Link className="s-sidebar__trigger" to="#">
        <i className="fa fa-bars mr-3" />
      </Link>

      <nav className="s-sidebar__nav">
        <Link className="navbar-brand-logo" to="/customer-support">
          <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
        </Link>

        <ul className="">
          <span className="p-0 mt-3">
            <li className="s-sidebar__nav-link">
              <Link to="/customer-support" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971013/Group-3.png" alt="img" />
                </span>{' '}
                Overview
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <a href={`https://os.tizeti.com/main/#/overview?ciph=${ciph}`} target="_blank" className="make-bold">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
                </span>
                <small>New system (Beta v2.0)</small>
              </a>
            </li>

            {/* <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/customer-support/call-logs" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1544459675/call_log_icon.png" alt="img" />
                </span>{' '}
                Call Logs
              </Link>
            </li> */}

            {/* <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/customer-support/transactions" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-5.png" alt="img" />
                </span>{' '}
                Transactions
              </Link>
            </li> */}

            {Number(role_id) > 1 &&
              Number(role_id) !== 6 &&
              (email === 'customersupportDept@tizeti.com' ||
                email === 'kolawole.oyetunde@tizeti.com' ||
                email === 'lydia.anyimson@tizeti.com' ||
                email === 'innocent.awuja@tizeti.com') && (
                <li className="s-sidebar__nav-link d-none d-md-block">
                  {' '}
                  <Link to="/customer-support/leads" target="_self">
                    <span>
                      <img
                        src="https://res.cloudinary.com/teewhy/image/upload/v1556618815/QualityAssurance.svg"
                        alt="img"
                      />
                    </span>{' '}
                    Add LTE User
                  </Link>
                </li>
              )}
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/customer-support/customers" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>{' '}
                Customers
              </Link>
            </li>

            {(email === 'customersupportDept@tizeti.com' || email === 'oluwayemisi.olude@tizeti.com') && (
              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/shared/customer-details" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                  </span>{' '}
                  Customer Details
                </Link>
              </li>
            )}

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/call-log-analytics" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>{' '}
                Call Log Analytics
              </Link>
            </li>

            <RetentionSideBarItem
              link={PATHS.CUSTOMER_SUPPORT_BULK_CUSTOMER_DETAILS}
              name="Bulk Customer Details"
            />

            {['oluwayemisi.olude@tizeti.com', 'customersupportDept@tizeti.com'].includes(email) && (
              <RetentionSideBarItem link="/shared/statistics" name="STATISTICS" />
            )}

            {/* <li className="s-sidebar__nav-link">
              {' '}
              <Link to={PATHS.CUSTOMER_SUPPORT_UNVERIFIED_CUSTOMERS} target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>{' '}
                Unverified Customers
              </Link>
            </li> */}

            <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#basestations">
              <li>
                {' '}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link to="#" target="_self" className="">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
                  </span>
                  BASESTATIONS
                </Link>
              </li>
            </span>
            <span id="basestations" className="collapse base-li top-bottom pl-2">
              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/customer-support/basestations" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
                  </span>{' '}
                  BaseStations
                </Link>
              </li>

              <li className="sidebar-nav-link-li">
                <Link to="/customer-support/basestation-users" target="_self" className="mt-1">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
                  </span>
                  Basestation Users
                </Link>
              </li>

              <li className="sidebar-nav-link-li">
                <Link to="/customer-support/basestation-users-month" target="_self" className="mt-1">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
                  </span>
                  Monthly Basestation Users
                </Link>
              </li>
            </span>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/customer-support/schedule" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>{' '}
                Schedules
              </Link>
            </li>
            {(email === 'customersupportDept@tizeti.com' || email === 'oluwayemisi.olude@tizeti.com') && (
              <li className="s-sidebar__nav-link">
                <Link to="/customer-support/dedicated-customers" target="_self">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/idontknow.fw.png" alt="img" />
                  </span>
                  Dedicated Customers
                </Link>
              </li>
            )}
            <li className="s-sidebar__nav-link">
              <Link to="/support-calls" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group-4.png" alt="img" />
                </span>
                Support Calls
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              <Link to="/customer-support/voicemail" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group-4.png" alt="img" />
                </span>
                VoiceMail
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/shared/installations" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>{' '}
                Send Feedback
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/device-type" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>{' '}
                Device Types
              </Link>
            </li>

            {localStorage.getItem('roleId') > 1 && localStorage.getItem('roleId') <= 3 && (
              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/extension-log-report" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                  </span>{' '}
                  Extension Reports
                </Link>
              </li>
            )}
            <li className="s-sidebar__nav-link">
              <Link to="/shared/qa-installations" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                QA Installation Report
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to={PATHS.CUSTOMER_SUPPORT_CALL_REPORT} target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-4.png" alt="img" />
                </span>{' '}
                Call Report
              </Link>
            </li>

            {/* {Number(role_id) > 1 &&
              Number(role_id) !== 6 &&
              (  email === 'customersupportDept@tizeti.com' ||
               email === 'funmilayo.adesanya@tizeti.com' ||
               email === 'marian.sulaimon@tizeti.com' ||
             email === 'victor.george@tizeti.com' ||
             email === 'kabiru.balogun@tizeti.com') && (
                <RetentionSideBarItem
                  link={PATHS.CUSTOMER_SUPPORT_ACTIVE_AND_INACTIVE_CUSTOMERS}
                  name="Active/Inactive Customers"
                />
              )} */}

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/customer-support/expired-users" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png" alt="img" />
                </span>{' '}
                Expired Users
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/customer-support/usage-history" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-4.png" alt="img" />
                </span>{' '}
                Usage History
              </Link>
            </li>

            {['oluwayemisi.olude@tizeti.com', 'customersupportDept@tizeti.com'].includes(email) ? (
              <RetentionSideBarItem link="/customer-support/active-zones" name="Active Customers Per Zone" />
            ) : null}
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/customer-support/usage-statistics" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-4.png" alt="img" />
                </span>{' '}
                Usage Statistics
              </Link>
            </li>

            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/customer-support/archives" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-4.png" alt="img" />
                </span>{' '}
                Archives
              </Link>
            </li>
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/customer-support/relocation" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>{' '}
                Relocation
              </Link>
            </li>
            {Number(role_id) > 1 ? (
              <li className="s-sidebar__nav-link d-none d-md-block">
                {' '}
                <Link to="/customer-support/quality-assurance" target="_self">
                  <span>
                    <img
                      src="https://res.cloudinary.com/teewhy/image/upload/v1556618815/QualityAssurance.svg"
                      alt="img"
                    />
                  </span>{' '}
                  Quality Assurance
                </Link>
              </li>
            ) : (
              <li className="s-sidebar__nav-link d-none d-md-block">
                {' '}
                <Link to="/customer-support/personnel-quality-assurance" target="_self">
                  <span>
                    <img
                      src="https://res.cloudinary.com/teewhy/image/upload/v1556618815/QualityAssurance.svg"
                      alt="img"
                    />
                  </span>{' '}
                  Quality Assurance
                </Link>
              </li>
            )}

            <RetentionSideBarItem
              link={PATHS.CUSTOMER_SUPPORT_CREATE_CUSTOMER}
              name="Create Customer Dashboard"
            />
            <RetentionSideBarItem
              link={PATHS.CUSTOMER_SUPPORT_INSTALLATION_FEEDBACK}
              name="Installation Feedback"
            />

            {/* <RetentionSideBarItem link={PATHS.CUSTOMER_SUPPORT_CALL_LOGS} name="Call Logs" /> */}

            {[
              'marian.ishokare@tizeti.com',
              'gbenga.diya@tizeti.com',
              'ijem.okolugbo@tizeti.com',
              'customersupportDept@tizeti.com',
            ].includes(email) ? (
              <RetentionSideBarItem
                link={PATHS.CUSTOMER_SUPPORT_ACTIVE_AND_INACTIVE_CUSTOMERS_RESTRICTED}
                name="Restricted Active/Inactive Customers"
              />
            ) : null}

            <RetentionSideBarItem
              link={PATHS.CUSTOMER_SUPPORT_ACTIVE_AND_INACTIVE_CUSTOMERS}
              name="Active/Inactive Customers"
            />

            <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#lte">
              <RetentionSideBarItem
                link="#"
                name="LTE"
                image="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png"
              />
            </span>
            <span id="lte" className="collapse base-li top-bottom">
              <RetentionSideBarItem
                link={PATHS.CUSTOMER_SUPPORT_LTE_INSTALLATIONS}
                name={<span style={{ fontSize: '13px' }}>LTE Installations</span>}
              />
              <RetentionSideBarItem
                link={PATHS.CUSTOMER_SUPPORT_EXPIRED_LTE_CUSTOMERS}
                name={<span style={{ fontSize: '13px' }}>LTE Expired Customers</span>}
              />
              <RetentionSideBarItem
                link={PATHS.CUSTOMER_SUPPORT_ACTIVE_LTE_CUSTOMERS}
                name={<span style={{ fontSize: '13px' }}>LTE Active Customers</span>}
              />
              <RetentionSideBarItem
                link={PATHS.CUSTOMER_SUPPORT_LTE_CUSTOMERS}
                name={<span style={{ fontSize: '13px' }}>LTE Customers</span>}
              />
            </span>

            <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#installs">
              <RetentionSideBarItem
                link="#"
                name="INSTALLATIONS"
                image="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png"
              />
            </span>
            <span id="installs" className="collapse base-li top-bottom">
              <RetentionSideBarItem
                link={PATHS.CUSTOMER_SUPPORT_INSTALLATIONS_PER_COUNTRY}
                name={<span style={{ fontSize: '13px' }}>Installations Per Country</span>}
              />
              <RetentionSideBarItem
                link={PATHS.CUSTOMER_SUPPORT_ASSET_TEAM_INSTALLATIONS}
                name={<span style={{ fontSize: '13px' }}>Asset Team Installations</span>}
              />
            </span>

            <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#active">
              <RetentionSideBarItem
                link="#"
                name="ASSET TEAM ACTIVE"
                image="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png"
              />
            </span>
            <span id="active" className="collapse base-li top-bottom">
              <RetentionSideBarItem
                link={PATHS.CUSTOMER_SUPPORT_ACTIVE_CUSTOMERS}
                name={<span style={{ fontSize: '13px' }}>Active Customers</span>}
              />
              <RetentionSideBarItem
                link={PATHS.CUSTOMER_SUPPORT_ACTIVE_CUSTOMERS_PER_COUNTRY}
                name={<span style={{ fontSize: '13px' }}>Active Customers Per Country</span>}
              />
            </span>

            <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#expired">
              <RetentionSideBarItem
                link="#"
                name="ASSET TEAM EXPIRED"
                image="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png"
              />
            </span>
            <span id="expired" className="collapse base-li top-bottom">
              <RetentionSideBarItem
                link="/past-expired"
                name={<span style={{ fontSize: '13px' }}>Expired</span>}
              />
              <RetentionSideBarItem
                link={PATHS.CUSTOMER_SUPPORT_ASSET_EXPIRED}
                name={<span style={{ fontSize: '13px' }}>Total Expired</span>}
              />
              <RetentionSideBarItem
                link={PATHS.CUSTOMER_SUPPORT_ASSET_SEVEN_DAYS_EXPIRED}
                name={<span style={{ fontSize: '13px' }}>Seven Days Expired</span>}
              />
              <RetentionSideBarItem
                link={PATHS.CUSTOMER_SUPPORT_ASSET_EXPIRED_ZONES}
                name={<span style={{ fontSize: '13px' }}>Expired Per Zone</span>}
              />
              <RetentionSideBarItem
                link={PATHS.CUSTOMER_SUPPORT_EXPIRED_CUSTOMERS_PER_COUNTRY}
                name={<span style={{ fontSize: '13px' }}>Expired Customers Per Country</span>}
              />

              <RetentionSideBarItem
                link="/device-type"
                name={<span style={{ fontSize: '13px' }}>Device Types</span>}
              />
            </span>
            <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#retention">
              <RetentionSideBarItem
                link="#"
                name="RETENTION"
                image="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png"
              />
            </span>
            <span id="retention" className="collapse base-li top-bottom">
              <RetentionSideBarItem link={PATHS.CUSTOMER_SUPPORT_DEVICE_RETRIEVAL} name="Escalated Buckets" />
              <RetentionSideBarItem link={PATHS.CUSTOMER_SUPPORT_DELISTED_CUSTOMERS} name="Decommission DB" />
              <RetentionSideBarItem link={PATHS.CUSTOMER_SUPPORT_RETENTION_LOGS} name="Retention Logs" />

              {(email === 'customersupportDept@tizeti.com' || email === 'ijem.okolugbo@tizeti.com') && (
                <RetentionSideBarItem link="/customer-support/bulk-retrieval" name="Bulk Retrievals" />
              )}
              <RetentionSideBarItem
                link={PATHS.CUSTOMER_SUPPORT_FAILED_PAYSTACK_TRANSACTIONS}
                name="Failed Paystack Transactions"
              />
              <RetentionSideBarItem link={PATHS.CUSTOMER_SUPPORT_TRACKER} name="Subscription Tracker" />
            </span>

            {checkCountry()}
            <span className="s-sidebar__nav-link d-none d-md-block" data-toggle="collapse" data-target="#general">
              <RetentionSideBarItem
                link="#"
                name="GENERAL"
                image="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png"
              />
            </span>
            <span id="general" className="collapse base-li top-bottom">
              {Number(sessionStorage.getItem('roleId')) > 2 && (
                <>
                  <li className="s-sidebar__nav-link">
                    <a href={PATHS.CUSTOMER_SUPPORT_RECRUITMENT} rel="noopener noreferrer" target="_blank" className="">
                      <span>
                        <img
                          src="https://res.cloudinary.com/teewhy/image/upload/v1555319728/recruitment_1.svg"
                          alt="img"
                        />
                      </span>
                      {span13('Recruitment')}
                    </a>
                  </li>
                  <BudgetSideBar link={PATHS.CUSTOMER_SUPPORT_BUDGET} name={span13('Budget')} />
                </>
              )}
              <RetentionSideBarItem link="/customer-support/admin-requests" name={span13('Admin Request')} />
              <RetentionSideBarItem link="/customer-support/kpi-monitor" name={span13('KPI Monitor')} />
              <RetentionSideBarItem link="/customer-support/purchase-order" name={span13('Purchase Order')} />
              <RetentionSideBarItem link="/customer-support/escalate" name={span13('Escalate')} />
              <RetentionSideBarItem link="/customer-support/report-issue" name={span13('Report An Issue')} />
              <RetentionSideBarItem link="/customer-support/learning-portal" name={span13('Learning Portal')} />
              <RetentionSideBarItem link={PATHS.CUSTOMER_SUPPORT_PAYSLIP} name={span13('Payslip')} />
              {Number(role_id) ? (
                <RetentionSideBarItem
                  link="/customer-support/request-feature"
                  name={span13('Request A Feature')}
                />
              ) : null}
              <RetentionSideBarItem link="/customer-support/promoter-score" name={span13('Net Promoter Score')} />
            </span>
          </span>
        </ul>
      </nav>
    </div>
  );
};

export default RetentionSideBar;
