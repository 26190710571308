import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import * as helpers from './helpers';
import * as utilHelpers from '../../../../../utils/helpers';
import moment from 'moment';

// Add expiration to the columns
const columns = [
  { id: 'serial', label: 'Serial Number', minWidth: 150 },
  { id: 'name', label: 'Name', minWidth: 450 },
  { id: 'mail', label: 'Email', minWidth: 100 },
  { id: 'username', label: 'Mac_id', minWidth: 150, align: 'right' },
  { id: 'expiration', label: 'Expiration Date', minWidth: 150 },
  { id: 'status', label: 'Status', minWidth: 150, align: 'right' },
];

export default function ColumnGroupingTable() {
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [mac_id, setMacId] = React.useState('');
  const [MAC_type, setMAC_type] = React.useState('Non-LTE');
  // Removed filter state as we'll be filtering automatically

  async function getCustomerDowntime() {
    setIsLoading(true);
    try {
      const res = await axios.get(`https://api.tizeti.com/api/v1/users/getUsersNetworkStatus?mac_id=${mac_id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (res.data && res.data.status) {
        console.log('Customer Downtime', res.data.data);
        
        // Process data to handle expiration date and offline status
        const today = moment();
        const formattedData = res.data.data.map((data) => {
          // Check if status is offline before expiration calculation
          if (data.status === 'offline') {
            const expiration = data.expiration || moment(data.acctstarttime).format('DD MMM YYYY');
            const isAfterToday = moment(expiration, 'DD MMM YYYY').isAfter(today);
            return {
              ...data,
              serial: 0, // temporary; will re-index after filtering
              expiration,
              offlineByExpiration: isAfterToday,
            };
          }
          // If not offline, skip expiration logic
          return {
            ...data,
            serial: 0, // temporary; will re-index after filtering
            expiration: '',
            offlineByExpiration: false,
          };
        });
        setRows(formattedData);
      }
    } catch (error) {
      console.log('An error occurred', error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSpaceBar = (e) => {
    if (e.keyCode === 32) e.preventDefault();
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (MAC_type === 'Non-LTE') return setMacId(helpers.NonLTEInput(value, mac_id));
    return setMacId(helpers.LTEInput(value));
  };

  React.useEffect(() => {
    getCustomerDowntime();
  },[])

  // Filter to show only offline devices or expired devices
  const filteredRows = rows.filter((row) => {
    // Show if status is offline AND expiration > today
    return row.status === 'offline' && row.offlineByExpiration;
  });

  // Re-index so serial starts at 1
  const reindexedRows = filteredRows.map((row, i) => ({
    ...row,
    serial: i + 1,
  }));

  return (
    <Paper sx={{ maxWidth: '95%', overflowX: 'auto', px: '2rem', pb: '4rem' }}>
      <Box sx={{ mt: '6rem' }}>
        <Typography variant="body" color="#666666">
          Radio MAC type:
        </Typography>
        <div className="flex flex-row gap-3 mt-[15px]">
          <input
            type="radio"
            name="MAC_type"
            id="LTE"
            value="LTE"
            checked={MAC_type === 'LTE'}
            onChange={(e) => {
              setMAC_type(e.target.value);
              setMacId('');
            }}
          />{' '}
          LTE
          <input
            type="radio"
            name="MAC_type"
            id="Non-LTE"
            value="Non-LTE"
            checked={MAC_type === 'Non-LTE'}
            onChange={(e) => {
              setMAC_type(e.target.value);
              setMacId('');
            }}
          />{' '}
          Non-LTE
        </div>
        <div className="flex">
          <input
            type="text"
            className="w-[90%] h-10 p-2 mt-[15px] rounded border-solid border "
            placeholder={MAC_type === 'Non-LTE' ? 'xx:xx:xx:xx:xx:xx' : 'xxxxxxxxxxxxxxx'}
            required
            name="mac_id"
            value={mac_id}
            onKeyDown={handleSpaceBar}
            onChange={handleChange}
            maxLength={MAC_type === 'Non-LTE' ? 17 : 15}
            pattern={
              MAC_type === 'Non-LTE'
                ? '[0-9A-Z]{2}:[0-9A-Z]{2}:[0-9A-Z]{2}:[0-9A-Z]{2}:[0-9A-Z]{2}:[0-9A-Z]{2}'
                : '[0-9]{15}'
            }
            title={
              MAC_type === 'Non-LTE'
                ? 'Please enter a valid Non-LTE MAC ID (XX:XX:XX:XX:XX:XX)'
                : 'Please enter a valid 15-digit LTE MAC ID (XXXXXXXXXXXXXXX)'
            }
            disabled={isLoading}
          />
          <button
            className="btn btn-green py-2 px-5 ml-4"
            disabled={
              isLoading ||
              (MAC_type === 'Non-LTE' && mac_id.length !== 17) ||
              (MAC_type === 'LTE' && mac_id.length !== 15)
            }
            onClick={(e) => {
              e.preventDefault();
              getCustomerDowntime();
            }}
          >
            {isLoading ? 'Searching...' : 'Search'}
          </button>
        </div>
        {/* Filter buttons removed as per request */}
        {/* <div className="flex" style={{ gap: '1rem' }}>
          <button className="btn btn-secondary" onClick={() => setFilter('all')}>
            All
          </button>
          <button className="btn btn-success" onClick={() => setFilter('online')}>
            Online
          </button>
          <button className="btn btn-danger ml-3" onClick={() => setFilter('offline')}>
            Offline
          </button>
        </div> */}
      </Box>
      {/* Title for clarity */}
      <Box mt={3}>
        <Typography variant="h6" color="primary">
          Offline Devices
        </Typography>
      </Box>
      <TableContainer sx={{ px: '1rem' }}>
        <Table aria-label="plain table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  Loading...
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {reindexedRows.length > 0 ? (
                reindexedRows.map((row) => (
                  <TableRow hover tabIndex={-1} key={row.username + '-' + row.acctstarttime}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === 'status' ? (
                            <Chip
                              label={row.offlineByExpiration ? 'EXPIRED' : value} 
                              variant="outlined" 
                              color={value === 'online' ? 'success' : 'error'} 
                            />
                          ) : column.format && typeof value === 'number' ? (
                            column.format(value)
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No offline devices found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
}
