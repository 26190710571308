import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import './MissingDocs.css'; // Import the CSS file for styling
import AssetTeamNavBar from '../../../../layouts/asset-team/AssetTeamNavBar';
import { useUser } from '../../../../../hooks/shared/user/useUser';
export default function MissingDocs() {
  const { profile, user } = useUser();
  const { firstName } = profile;
  const { role_id } = user;
  const [users, setUsers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const usersRef = useRef([]);

  const usersPerPage = 100;

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://api.tizeti.com:8443/api/v1/users/missingDocs', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status === 'success') {
        usersRef.current = response.data.users;
        setUsers(response.data.users);
        setDisplayedUsers(response.data.users.slice(0, usersPerPage));
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const startIndex = (pageNumber - 1) * usersPerPage;
    setDisplayedUsers(usersRef.current.slice(startIndex, startIndex + usersPerPage));
  };

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    const filteredUsers = usersRef.current.filter((user) => user.email.toLowerCase().includes(term));
    setDisplayedUsers(filteredUsers.slice(0, usersPerPage));
    setCurrentPage(1);
  };

  const handleRefresh = () => {
    fetchUsers();
  };

  return (
    <div>
      <div className="s-layout">
        <main className="s-layout__content col-lg-10 p-0">
          <AssetTeamNavBar currentPage="Missing Documents" position={firstName} />
          <div className="mt-5">
            <div className="missing-docs-container">
              <div className="flex-container">
                <input
                  type="text"
                  placeholder="Search by email"
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{ width: '15rem' }}
                />
                <div>
                  Count of missing documents: <span>{users.length}</span>
                </div>
                <button onClick={handleRefresh} style={{ borderRadius: '0.25rem' }}>
                  Refresh
                </button>
                <CSVLink
                  data={usersRef.current}
                  filename="missing_docs.csv"
                  className="btn btn-green"
                  style={{ color: 'white' }}
                >
                  Download CSV
                </CSVLink>
              </div>
              {loading ? (
                <div className="spinner"></div>
              ) : (
                <>
                  <table className="missing-docs-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>MAC ID</th>
                        <th>Expiry Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedUsers.map((user) => (
                        <tr key={user.id}>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{user.phoneNumber}</td>
                          <td>{user.mac_id}</td>
                          <td>{user.expiration_date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="pagination">
                    {Array.from({ length: Math.ceil(usersRef.current.length / usersPerPage) }, (_, index) => (
                      <button key={index} onClick={() => handlePageChange(index + 1)}>
                        {index + 1}
                      </button>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
