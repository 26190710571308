/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useUserProfile } from '../../../../../hooks/shared/user/useUserProfile';
import BusinessDevelopmentNavBar from '../../../../layouts/business-development/BusinessDevelopmentNavBar';
import Requisitions from '../../../shared/requisition/shared/Requisitions';
import DataAnalysisNavBar from '../../../../layouts/data-analysis /DataAnalysisNavBar';

const DataAnalysisRequisition = () => {
  const { getUserProfile, profile } = useUserProfile();
  const { firstName } = profile;

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div className="s-layout">
      <main className="s-layout__content col-lg-10 p-0">
        <DataAnalysisNavBar currentPage="Requisition" position={firstName} />
        <div className="col-lg-12">
          <Requisitions />
        </div>
      </main>
    </div>
  );
};

export default DataAnalysisRequisition;
