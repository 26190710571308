/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
import React, { useState } from 'react';
import * as url from '../../../../../services/api/shared/GeniatechUrl';

const SearchByDetails = ({ getData, fetchData, getDataLegacy }) => {
  const detailsState = {
    option: '',
    optionValue: '',
  };
  const [details, setDetails] = useState(detailsState);
  const { option, optionValue } = details;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const searchForUser = (e) => {
    e.preventDefault();
    switch (option) {
      case 'email':
        const emailSearch = {
          email: optionValue.trim(),
        };
        return getData(`${url.GENIATECH_USER_INFORMATION_URL}`, emailSearch);

      case 'name':
        const nameSearch = {
          name: optionValue.trim(),
        };
        return fetchData(`${url.GENIATECH_GET_USER_BY_NAME_URLS}`, nameSearch);

      case 'phone':
        const phoneSearch = {
          phoneNumber: optionValue.trim(),
          macAddress: '12',
          category: 'phoneNumber',
        };
        return getData(`${url.GENIATECH_GET_USER_WITH_PHONE_OR_MAC_URL}`, phoneSearch);

      case 'macid':
        const macidSearch = {
          macAddress: optionValue.trim(),
          phoneNumber: 'N/A',
          macid: optionValue.trim(),
          category: 'macaddress',
        };
        return getData(`${url.GENIATECH_GET_USER_WITH_PHONE_OR_MAC_URL}`, macidSearch);

      case 'reference':
        const referenceNumberSearch = {
          reference: optionValue.trim(),
          phoneNumber: 'N/A',
          macAddress: 'N/A',
          action: 'getUserInformationWithPhoneOrMac',
          category: 'reference',
        };
        return getDataLegacy(`${url.SEARCH_FOR_CUSTOMER_BY_REFRENCE_URL}`, referenceNumberSearch);

      default:
        return null;
    }
  };

  return (
    <div className="mt-1">
      <form onSubmit={searchForUser} className="form-inline inner-addon d-flex">
        <div className="input-group mb-3 mr-3">
          <select name="option" onChange={handleChange} className="form-control mr-auto empty mt-1">
            <option value="">--Choose Search Field--</option>
            <option value="email">Email</option>
            <option value="name">Name</option>
            <option value="phone">Phone</option>
            <option value="macid">MAC ID</option>
            <option value="reference">Account Number</option>
          </select>
        </div>

        {/* {displayCountryDropdown(option, onCountryChange)} */}

        <div className="input-group mb-3 mr-3">
          <input
            className="form-control py-2 mr-auto empty pl-5"
            type="text"
            placeholder="search for user"
            name="optionValue"
            value={optionValue}
            onChange={handleChange}
          />
          <span className="fa fa-search" />
        </div>

        <div className="mb-3">
          <button type="submit" className="btn btn-green">
            Search By Details
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchByDetails;
