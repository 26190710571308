import { API_URL } from '../../../utils/config';

export const GENIATECH_USER_INFORMATION_URL = `${API_URL}/getUserInformation`;
export const GENIATECH_GET_USER_WITH_PHONE_OR_MAC_URL = `${API_URL}/getUserInfoWithPhoneorMac`;
export const GENIATECH_GET_USER_WITH_MAC_URL = `${API_URL}/gen2/searchbymac`;
export const GENIATECH_GET_USER_BY_NAME_URL = `${API_URL}/geniatech/getUserByNamePaginated`;
export const GENIATECH_GET_USER_BY_NAME_URLS = `${API_URL}/geniatech/getuserbyname`;
export const GENIATECH_GET_USER_PASSWORD_URL = `${API_URL}/geniatech/getUserpassword`;
export const GENIATECH_GET_PAYMENT_HISTORY_URL = `https://legacy.tizeti.com/frontaccountapi/account.php`;
export const GENIATECH_GET_USER_AUTH = `${API_URL}/getUserauth`;
export const GENIATECH_GET_USER_SESSION_URL = `${API_URL}/geniatech/usersession`;
export const GENIATECH_NEW_GET_USER_PASSWORD_URL = `https://tizeti.com/williams_rd/geniatechapi/index.php`;
export const GENIATECH_GET_USER_AUTH_URL = `${API_URL}/getUserauth`;
export const GET_CUSTOMER_ADDRESS_URL = `https://legacy.tizeti.com/geniatechapi/index.php`;
export const DELETE_CUSTOMER_ACCOUNT_URL = 'https://legacy.tizeti.com/geniatechapi/index.php';
export const GET_USER_PLAN_URL = 'https://legacy.tizeti.com/frontaccountapi/account.php';
export const GENIATECH_GET_USER_WITH_ROUTER_TAG = `${API_URL}/account/searchaccount`;
export const SEARCH_FOR_CUSTOMER_URL = `${API_URL}/users/searchuser`;
export const SEARCH_FOR_CUSTOMER_BY_REFRENCE_URL = `https://legacy.tizeti.com/geniatechapi/index.php`;
export const SEARCH_FOR_ACCOUNT_URL = `${API_URL}/account/searchaccount`;
