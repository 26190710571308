/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import useAccountExtension from '../../../../hooks/shared/asset-team/account-extension/useAccountExtension';
import { useUser } from '../../../../hooks/shared/user/useUser';
import { GET_ROUTER_URL, DOWNLOAD_ACCOUNT_EXTENSION_SAMPLE } from '../../../../services/api/shared/BasestationsUrl';

const AccountExtension = () => {
  const {
    profile: { email },
  } = useUser();

  const { loading, extendAccount } = useAccountExtension();
  const [file, setFile] = useState('');
  const [reason, setReason] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    const params = new FormData();
    params.append('action', 'bulk_account_extension');
    params.append('staff_email', email);
    params.append('file', file);
    params.append('reason', reason);
    extendAccount(`${GET_ROUTER_URL}`, params);
  };

  const submitForm = () => {
    return file && file.name.length > 0 && reason.length > 0;
  };

  return (
    <div>
      {email &&
        // email === 'customersupportDept@tizeti.com' ||

        //   email === 'ijem.okolugbo@tizeti.com'
        (email === 'hakeem.balogun@tizeti.com' || email === 'financeDept@tizeti.com' || email === 'henrietta.alohan@tizeti.com' || email === 'dataDept@tizeti.com') && (
          <div className="">
            <button type="button" className="btn btn-green" data-toggle="modal" data-target="#extendAccount">
              Account Extension
            </button>
          </div>
        )}
      <div id="extendAccount" className="modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header shadow">
              <h4 className="modal-title update_asset">Extend Customer</h4>
              <button type="button" className="btn btn-outline-info btn-circle btn-close" data-dismiss="modal">
                x
              </button>
            </div>
            <div className="modal-body detail mb-4 p-0 pt-2">
              <form onSubmit={onSubmit} className="col-md-12">
                <div className="">
                  <div className="form-group col-md-12">
                    <label className="m-0 p-0 copy-font">
                      <p className="m-0 p-0 text-danger text-uppercase copy-font">
                        The same file name cannot be re-used. Always rename it.{' '}
                        <a href={DOWNLOAD_ACCOUNT_EXTENSION_SAMPLE}>Download Format</a>
                      </p>
                    </label>
                  </div>
                </div>

                <div className="">
                  <div className="form-group col-md-12">
                    <label className="m-0 p-0 copy-font" htmlFor="file">
                      File
                    </label>
                    <input
                      type="file"
                      className="form-control border border-info py-2"
                      id="file"
                      name="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </div>
                </div>

                <div className="">
                  <div className="form-group col-md-12">
                    <label className="m-0 p-0 copy-font">Reason</label>
                    <textarea
                      className="form-control border border-info"
                      rows="3"
                      cols="80"
                      name="reason"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    />
                  </div>
                </div>

                <div className="text-center mt-3">
                  {loading ? (
                    <button type="submit" className="btn btn-green py-2 px-5" disabled>
                      Extending
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-green py-2 px-5" disabled={!submitForm()}>
                      Extend
                    </button>
                  )}
                  {/* <button type="submit" className="btn btn-green py-2 px-5" disabled={!submitForm()}>
                    Extend
                  </button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountExtension;
