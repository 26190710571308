import React, { useState } from 'react';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import axios from 'axios'; // Import axios for API calls
import { useCustomer } from '../../../../hooks/shared/customers/useCustomers';
import { FaCheckCircle } from 'react-icons/fa';

const API_URL = 'https://api.tizeti.com/api/v1/getUserInfoWithPhoneorMac';

const BulkExtension = () => {
  const { extendCustomer, extendingCustomer } = useCustomer();
  const [customers, setCustomers] = useState([]);
  const [updatedValues, setUpdatedValues] = useState({}); // Stores fetched values

  const [originalValues, setOriginalValues] = useState({}); // Store initial values

  const sampleCSV = 'Username,Name,Duration,Reason\n00:1A:2B:3C:4D:5E,John Doe,30,Subscription renewal';

  const handleDownloadSample = () => {
    const blob = new Blob([sampleCSV], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'sample.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Add this button in your component's JSX

  // Fetch user info (to get value field)
  const getUserInfo = async (macAddress) => {
    try {
      const response = await axios.post(
        API_URL,
        { macAddress, phoneNumber: 'N/A' },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } },
      );

      if (response.data[0] && response.data[0].value) {
        return response.data[0].value;
      } else {
        return 'N/A';
      }
    } catch {
      return 'Error fetching Expiry Date';
    }
  };

  // Inside handleFileUpload, set original values
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      complete: async (result) => {
        const parsedData = result.data
          .slice(1)
          .map((row) => ({
            username: row[0] ? row[0].trim() : '',
            name: row[1] ? row[1].trim() : '',
            duration: row[2] ? row[2].trim() : '',
            reason: row[3] ? row[3].trim() : '',
          }))
          .filter((item) => item.username && item.name && item.duration && item.reason);

        setCustomers(parsedData);

        // Fetch and store both original and updated values
        const valuesMap = {};
        const originalMap = {};
        for (const customer of parsedData) {
          const expiryDate = await getUserInfo(customer.username);
          valuesMap[customer.username] = expiryDate;
          originalMap[customer.username] = expiryDate;
        }
        setUpdatedValues(valuesMap);
        setOriginalValues(originalMap);
      },
      header: false,
      skipEmptyLines: true,
    });
  };

  // Handle Single Customer Extension
  const handleExtend = async (customer) => {
    const { username, duration, reason, name } = customer;

    const data = { username, duration };
    const extendLog = {
      macid: username,
      extending_days: duration,
      comment: reason,
      name,
      extension_type: 'extension',
    };

    try {
      await extendCustomer(data, extendLog);

      // Fetch updated value after successful extension
      const updatedValue = await getUserInfo(username);
      setUpdatedValues((prev) => ({ ...prev, [username]: updatedValue }));
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      if (error.response) {
        // Server responded with an error status
        errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : `Error: ${error.response.status}`;
      } else if (error.request) {
        // Request was made but no response received
        errorMessage = 'No response from server';
      } else {
        // Something happened while setting up the request
        errorMessage = error.message;
      }
      setUpdatedValues((prev) => ({ ...prev, [username]: errorMessage }));
    }
  };

  // Handle Extending All Customers
  const handleExtendAll = () => {
    customers.forEach((customer) => handleExtend(customer));
  };

  // Refresh the view
  const handleRefresh = () => {
    setCustomers([]);
    setUpdatedValues({});
  };

  return (
    <div className="container mt-4">
      {/* File Upload & Refresh Button */}
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-stretch gap-2 mb-3">
        <input type="file" accept=".csv" onChange={handleFileUpload} className="form-control w-100 w-md-50" />
        <button className="btn btn-secondary w-100 w-md-auto" onClick={handleRefresh}>
          Refresh
        </button>
        <button className="btn btn-info w-100 w-md-auto" onClick={handleDownloadSample}>
          Download Sample CSV
        </button>
      </div>

      {/* Extend All Button */}
      {customers.length > 0 && (
        <div className="text-right mb-3">
          <button className="btn btn-primary" onClick={handleExtendAll} disabled={extendingCustomer}>
            {extendingCustomer ? 'Extending All...' : 'Extend All'}
          </button>
        </div>
      )}

      {/* Data Table */}
      {customers.length > 0 && (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Username</th>
              <th>Name</th>
              <th>Duration (Days)</th>
              <th>Reason</th>
              <th>Expiry Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {customers.map((customer, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{customer.username}</td>
                <td>{customer.name}</td>
                <td>{customer.duration}</td>
                <td>{customer.reason}</td>
                <td>
                  {updatedValues[customer.username] || 'Loading...'}
                  {updatedValues[customer.username] &&
                    updatedValues[customer.username] !== originalValues[customer.username] && (
                      <FaCheckCircle className="text-success" />
                    )}
                </td>
                <td>
                  <button
                    className="btn btn-success"
                    onClick={() => handleExtend(customer)}
                    disabled={extendingCustomer}
                  >
                    {extendingCustomer ? 'Extending...' : 'Extend'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

BulkExtension.propTypes = {
  extendCustomer: PropTypes.func,
  extendingCustomer: PropTypes.bool,
};

export default BulkExtension;
