import { useEffect, useReducer } from 'react';
import axios from 'axios';
import qs from 'qs';
import toastr from 'toastr';
import * as load from '../../../constants/types/shared/LoadingTypes';
import * as types from '../../../constants/types/sales/installations/InstallationsTypes';
import { GET_ZONES } from '../../../constants/types/shared/zones/ZonesTypes';
import { GET_ZONES_URL } from '../../../services/api/shared/installations/ZonesUrl';
import * as link from '../../../services/api/shared/installations/InstallationsUrl';
import { installationsReducer } from '../../../reducers/shared/installations/installationsReducer';
import * as url from '../../../services/api/shared/installations/AssetZoneURL';

export const useInstallations = () => {
  const [
    {
      count,
      installations,
      pages,
      isLoading,
      zones,
      fetching,
      dimension1,
      dimension2,
      calledstation,
      radacct,
      allCalledStations,
      isFetching,
      isChecking,
      isValid,
      validMAC,
    },
    dispatch,
  ] = useReducer(installationsReducer, {
    count: 0,
    installations: [],
    pages: 1,
    isLoading: false,
    isFetching: false,
    isChecking: false,
    fetching: false,
    zones: [],
    dimension1: [],
    dimension2: [],
    allCalledStations: [],
    calledstation: [],
    radacct: [],
    isValid: '',
    validMAC: '',
  });

  const getInstallationsCount = async () => {
    try {
      const response = await axios.get(`${link.GET_INSTALLATIONS_URL}/1`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_INSTALLATIONS,
          payload: response.data.count,
        });
      }
    } catch (e) {
      if (e) {
        toastr.info('Something went wrong!');
      }
    }
  };

  const editInstallationStatus = async (id, data) => {
    try {
      const response = await axios.patch(`${link.EDIT_INSTALLATION_STATUS_URL}/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        toastr.success('Installation status has been updated!');

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      if (err) {
        toastr.info(err.response.data.message);
      }
    }
  };

  const getInstallations = async () => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${link.GET_INSTALLATIONS_URL}/1`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_ALL_INSTALLATIONS,
          payload: {
            installations: response.data.rows,
            count: response.data.count,
            pages: response.data.pages,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const searchInstallations = async (data) => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.post(`${link.SEARCH_INSTALLATION_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_ALL_INSTALLATIONS,
          payload: {
            installations: response.data.rows,
            count: response.data.count,
          },
        });
      }
    } catch (e) {
      toastr.info(e.response.data.message);
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  const getZones = async () => {
    dispatch({ type: load.LOADING_STARTS });
    try {
      const response = await axios.get(`${GET_ZONES_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: GET_ZONES,
          payload: response.data,
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.LOADING_STOPS });
    }
  };

  // const getDimensions = async () => {
  //   dispatch({ type: load.FETCHING_STARTS });
  //   try {
  //     const response = await axios.get(`${link.GET_DIMENSIONS_URL}`, {
  //       headers: {
  //         Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  //       },
  //     });
  //     if (response.data) {
  //       dispatch({
  //         type: types.GET_DIMENSIONS,
  //         payload: response.data.items,
  //       });
  //     }
  //   } catch (e) {
  //     toastr.info('Something went wrong!');
  //     dispatch({ type: load.FETCHING_STOPS });
  //   }
  // };

  const getDimension1 = async () => {
    try {
      const data = { action: 'fetchZones' };
      const response = await axios.post(`${link.LEGACY_FRONTACCOUNT_URL}`, qs.stringify(data));
      if (response.data) {
        dispatch({
          type: types.GET_DIMENSION1,
          payload: response.data.items,
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
    }
  };

  const getDimension2 = async (zone) => {
    dispatch({ type: load.FETCHING_DIMENSION_STARTS });
    try {
      const data = { action: 'fetchZoneCalledStation', zone };
      const response = await axios.post(`${link.LEGACY_FRONTACCOUNT_URL}`, qs.stringify(data));
      if (response.data.status) {
        dispatch({
          type: types.GET_DIMENSION2,
          payload: response.data.items,
        });
      } else dispatch({ type: load.FETCHING_DIMENSION });
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.FETCHING_DIMENSION_STOPS });
    }
  };

  const getCalledStation = async (mac) => {
    dispatch({ type: load.FETCHING_STARTS });
    try {
      const response = await axios.get(`${link.GET_CALL_STATION_URL}?macid=${mac}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_CALL_STATION,
          payload: {
            calledstation: response.data.items,
            radacct: response.data.calledstation_on_radacct,
          },
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.FETCHING_STOPS });
    }
  };

  const createCalledStation = async (data1, data2) => {
    dispatch({ type: load.FETCHING_STARTS });
    try {
      const response = await Promise.all([
        axios.post(`${link.GET_CALL_STATION_URL}`, qs.stringify(data1), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
        axios.post(`${link.LEGACY_FRONTACCOUNT}`, data2, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }),
      ]);
      if (response[0].data.status) toastr.success('Edit Successful!');
      if (response[1].data.status) toastr.success('Edited Successfully on FrontAccounting!');
    
    } catch (e) {
      toastr.error('Something went wrong!', e.response.data.message);
      dispatch({ type: load.FETCHING_STOPS });
    }
  };

  const getAllCalledStations = async () => {
    dispatch({ type: load.FETCHING_STARTS });
    try {
      const response = await axios.get(`${url.GET_ALL_CALL_STATIONS_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: types.GET_ALL_CALL_STATIONS,
          payload: response.data,
        });
      }
    } catch (e) {
      toastr.info('Something went wrong!');
      dispatch({ type: load.FETCHING_STOPS });
    }
  };

  const checkMACID = async (mac) => {
    dispatch({ type: load.CHECKING_MAC_STARTS });
    try {
      const data = { action: 'checkActivationStatus', mac };
      const response = await axios.post(`${link.LEGACY_GENIATECH_URL}`, qs.stringify(data));
      if (response.data.status) {
        dispatch({
          type: types.CHECK_MAC_ID,
          payload: {
            isValid: true,
            validMAC: response.data.macaddress,
          },
        });
        setTimeout(() => {
          dispatch({
            type: types.CHECK_MAC_ID,
            payload: {
              isValid: '',
              validMAC: response.data.macaddress,
            },
          });
        }, 10000);
      } else {
        dispatch({
          type: types.CHECK_MAC_ID,
          payload: { isValid: false },
        });
        setTimeout(() => {
          dispatch({
            type: types.CHECK_MAC_ID,
            payload: { isValid: '' },
          });
        }, 10000);
      }
    } catch (e) {
      toastr.info(e.response.data.message);
      dispatch({ type: load.CHECKING_MAC_STOPS });
    }
  };

  useEffect(() => {
    getInstallationsCount();
    getAllCalledStations();
    getInstallations();
    getDimension1();
    getZones();
  }, []);

  return {
    count,
    installations,
    pages,
    isLoading,
    isChecking,
    isFetching,
    zones,
    fetching,
    dimension1,
    dimension2,
    radacct,
    calledstation,
    allCalledStations,
    isValid,
    validMAC,
    checkMACID,
    getDimension2,
    getCalledStation,
    createCalledStation,
    editInstallationStatus,
    searchInstallations,
  };
};

export default useInstallations;
