import React from 'react'
import { NoItemRow } from '../../../../../utils/loader/loadingHelper';
import loader from '../../../../../../src/assets/img/icons/TizetiLoaderColored.gif';
import { useCompletedInstallations } from '../../../../../hooks/accounts/useCompletedInstallations';
import ReactPaginate from 'react-paginate';
import helpers from '../../../../../utils/helpers';
import CustomInput from '../../../shared/forms/CustomInput';

export default function CompletedInstallationsBody() {
    const {isLoading, list, handlePageClick, allPages, page, count, handleSetOptions, options, zoneList, getCompletedInstallations} = useCompletedInstallations()

    const onSubmit = (e) => {
      e.preventDefault()
      getCompletedInstallations(page, options)
    }
    return <main>
         <form className="ml-3 form-inline" style={{marginTop: "5rem"}} onSubmit={onSubmit}>

         <div className="form-group d-flex">
          <small className="mr-1 ml-3 mt-2 copy-font">From:</small>
          <input
            placeholder=""
            readOnly={false}
            type="date"
            classname="form-control"
            name="from"
            value={options.from}
            onChange={handleSetOptions}
          />
          <small className="mr-1 ml-3 mt-2 copy-font">To:</small>
          <input
            placeholder=""
            readOnly={false}
            type="date"
            classname="form-control"
            name="to"
            value={options.to}
            onChange={handleSetOptions}
          />
        </div>
            <div className="">
              <div className="form-group mr-2">
                  <small className="mr-1 ml-3 mt-2 copy-font">Country:</small>
                <select
                  className="form-control"
                  id="country"
                  name="country"
                  value={options.country}
                  onChange={handleSetOptions}
                >
                  <option value="">Select an option</option>
                  <option value="ng">Nigeria</option>
                  <option value="gh">Ghana</option>
                  <option value="cv">Cote Divoire</option>
                </select>
                
                <div className="form-group mr-2 mt-3">
                  <small className="mr-1 ml-3 mt-2 copy-font">Account Type:</small>
                  <select
                    id="account_type"
                    className="form-control"
                    name="account_type"
                    value={options.account_type || ""}
                    onChange={handleSetOptions}
                  >
                    <option value="">Select an option</option>
                    <option value="home">Home</option>
                    <option value="lte">LTE</option>
                    <option value="fiber">Fiber</option>
                    <option value="dedicated">Dedicated & Turbo</option>
                  </select>
                </div>
           
                <div className="form-group mr-2 mt-3">
                  <small className="mr-1 ml-3 mt-2 copy-font">Zones:</small>
                  <select
                    id="zone_id"
                    className="form-control"
                    name="zone_id"
                    value={options.zone_id}
                    onChange={handleSetOptions}
                  >
                    <option value="">Select an option</option>
                   {
                    zoneList.length > 0 ? (
                    zoneList.map(getZone => <option key={getZone.id} value={getZone.id}>{getZone.name}</option>)
                  ):(
                    <option value="">Loading...</option>
                   )
                  }
                   
                  </select>
                </div>

              </div>
            </div>
            <div className="text-center mr-3 mt-3">
              <button type="submit" className="btn btn-green py-2 px-5">
                Search
              </button>
            </div>
          </form>
        <div className='px-3'>
            <p className='my-4' style={{fontSize: "20px", fontWeight: "600"}}>
                Page: {page}
            </p>

            <p className='my-4' style={{fontSize: "20px", fontWeight: "600"}}>
                Total installations : {count}
            </p>
        <Table list={list} isLoading={isLoading}/>
        </div>
   
        <div className="px-3">
        {allPages > 1 ? (
            <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={allPages}
                marginPagesDisplayed={3}
                pageRangeDisplayed={4}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                // subContainerClassName="pages pagination"
                activeClassName="active"
              />
            ) : null}
          </div>
    </main>
}



const Table = ({list, isLoading}) => {
    // Format currency based on country code
    const formatCurrency = (value, country) => {
        if (!value) return 'N/A';
        
        // Define currency symbols
        const currencySymbols = {
            'ng': '₦',
            'gh': 'GH₵',
            'cv': 'CFA'
        };
        
        // Get the appropriate symbol based on country
        const symbol = currencySymbols[country] || '';
        
        // Format number with commas
        const formattedValue = parseFloat(value).toLocaleString('en-US');
        
        return `${symbol}${formattedValue}`;
    };

    const tableData =  (list && list.length > 0 && list.map((item, i) => {
        const user = item.user
        const installs = item.installation
        const zone = item.baseStation.zone
        return (
            <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{`${user.firstName} ${user.lastName}`}</td>
       
            <td>{item.mac_id || 'N/A'}</td>
            <td>{item.device_type || 'N/A'}</td>
            <td>{zone.name || 'N/A'}</td>
            <td>{installs.payment_reference || 'N/A'}</td>
            <td>{helpers.formatDateAndTimes(item.created_at)}</td>
            <td>{installs.status}</td>
            <td>{user.email}</td>
            <td>{user.phoneNumber || 'N/A'}</td>
            <td>{item.account_type || 'N/A'}</td>
            <td>{formatCurrency(item.monthly_subscription, user.country)}</td>
            <td>{formatCurrency(item.setup, user.country)}</td>
            <td>{installs.installation_comment || 'N/A'}</td>
            <td>{installs.installation_note || 'N/A'}</td>
            {/* <td>{helpers.formatDateAndTimes(item.updatedAt)}</td> */}
          </tr>
        )
    }))

    return (
        <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr className="">
              <th>#</th>
              <th className="text-nowrap">Name</th>
           
              <th className="text-nowrap">Mac ID</th>
              <th className="text-nowrap">Device Type</th>
              <th className="text-nowrap">Zone</th>
              <th className="text-nowrap">Payment Reference</th>
              <th className="text-nowrap"> Date</th>
              <th className="text-nowrap"> status</th>
              <th className="text-nowrap">Email</th>
              <th className="text-nowrap">Phone No.</th>
              <th className="text-nowrap">Account type</th>
              <th className="text-nowrap">Monthly Subscription</th>
              <th className="text-nowrap">Setup Fee</th>
              <th className="text-nowrap">Installation Comment</th>
              <th className="text-nowrap">Installation Note</th>
              {/* <th className="text-nowrap"> Date Updated</th> */}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="text-center">
                  <img src={loader} alt="loading gif" />
                </td>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
  
      </div>
    )
}