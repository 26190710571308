/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import CustomerSupportNavBar from '../../../../layouts/customer-support/CustomerSupportNavBar';
import CustomerSupportVoiceMailTable from './CustomerSupportVoiceMailTable';

const CustomerSupportVoiceMail = () => {
  const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
  const tomorrow = new Date(Date.now() + 86400000).toISOString().split('T')[0]; // Get tomorrow’s date

  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(tomorrow);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);



  const fetchData = async () => {
    setLoading(true);

    const queryParams = qs.stringify({
      from: fromDate,
      to: toDate,
    });

    try {
      const response = await fetch(
        `https://api.tizeti.com/api/v1/voicemail/cdr?${queryParams}`, // ✅ Corrected
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            'Content-Type': 'application/json', // Optional, depends on API
          },
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setData(result);
    } catch (err) {
      console.log('Error fetching data:', err.message);
    } finally {
      setLoading(false);
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };
  useEffect(() => {
   
    fetchData();
  }, [fromDate, toDate]); // Fetch data when dates change

  return (
  <div className="s-layout">
       <main className="s-layout__content col-lg-10 p-0">
         <CustomerSupportNavBar currentPage="Voicemail CRM " />
    <div className="mt-5 p-3">
      <div className="container-fluid pt-4">
        <div className="row">
          <form className="form-inline mt-4 inner-addon d-flex"  onSubmit={onSubmit}>
            <div className="form-group mx-2">
              <label className="mt-2 p-0 copy-font">From: </label>
              <input
                className="form-control mr-2"
                name="from"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                readOnly={false}
                type="date"
              />

              <label className="mt-2 p-0 copy-font">To: </label>
              <input
                className="form-control mr-2"
                name="to"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                readOnly={false}
                type="date"
              />
            </div>

            <div className="mb-3">
              <button className="btn btn-green" style={{ height: '39px' }}>
                Search
              </button>
            </div>
          </form>
          {/* <div className="col-12 d-flex mt-2">
            <CustomCsvDownload
              data={data || []}
              headers={customerRevenueHeaders}
              filename={filename(`Customer_Revenue`)}
              classname="btn btn-green shadow-md"
              textcolor="white"
              text="Download Data"
            />
          </div> */}

          {/* <div className="pt-3 mx-3">
            Total Customer Revenue:
            <span className="badge badge-secondary ml-1">{total}</span>
          </div> */}

          <CustomerSupportVoiceMailTable items={data} isLoading={loading} />
        </div>
      </div>
    </div>
    </main>
    </div>
  );
};

export default CustomerSupportVoiceMail;
