/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/require-default-props */
import React from 'react';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import loader from '../../../../../assets/img/icons/TizetiLoaderColored.gif';
import { NOC } from '../../../../../utils/config';
import { SALES } from '../../../../../utils/departments/helpers';

const CustomerDetails = ({
  load,
  user,
  details,
  session,
  address,
  name,
  mail,
  zone,
  basestation,
  customerRef,
  itemprice,
  createdDate,
  username,
  installationDate,
  bizDevUserComment,
}) => {
  const { upload7days, upload30days, usage7days, usage30days, expiry_date } = details;
  const { ipaddress, lastconnected, onlinestatus, staticIpaddress } = session;
  console.log('🚀 ~ user:', installationDate);
  return (
    <div
      id="cus-details"
      className="modal fade in"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog pt-10" style={{ width: '600px' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="myModalLabel">
              Customer Details
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
          </div>

          <div className="modal-body detail" style={{ padding: '0', textAlign: 'justify' }}>
            {load ? (
              <img src={loader} alt="" />
            ) : (
              <div className="row">
                <div className="col-lg-12 d-flex d-fles">
                  <div className="col-lg-6 detail-label1">
                    <label id="fn">Name</label>
                    {/* <label id="ln">Last Name</label> */}
                    <label id="email">Email</label>
                    {username && <label id="mac">MAC ID</label>}
                    <label id="ip">IP</label>
                    <label>Monthly Plan</label>
                    {/* {Number(role_id) > 2 ? <label id="static">Static IP</label> : ''} */}
                    {/* <label htmlFor="">Address</label> */}
                    {/* {role >'2' ? <label id="email">Static IP</label> : ''} */}
                  </div>

                  <div className="col-lg-6 detail-result1">
                    <p className="mt-4 mb-1 p wrap">{name || null}</p>
                    <p className="mb-1 p">{mail || null}</p>
                    <p className="mb-1 p">{username || null}</p>
                    {ipaddress ? (
                      <p className="mb-1 p">
                        <a href={`http://${ipaddress}`} target="_blank">
                          {ipaddress}
                        </a>
                      </p>
                    ) : (
                      <p className="mb-1 p">N/A</p>
                    )}
                    {Number(`${sessionStorage.getItem('roleId')}`) > 1 ||
                    (staticIpaddress && `${sessionStorage.getItem('departmentId')}` === `${NOC}`) ? (
                      // eslint-disable-next-line react/jsx-indent
                      <p className="mb-1 p">{staticIpaddress}</p>
                    ) : null}
                    {itemprice >= 0 ? <p>{itemprice}</p> : <p>N/A</p>}
                    {/* <EditStaticIP usermac={mac} mac={UserName}/> */}
                  </div>
                </div>
                <hr />
                <div className="col-lg-12 d-flex d-fles">
                  <div className="col-lg-6 detail-label2">
                    <label>Upload/Download(7 days) </label>
                    <label>Upload/Download(30 days) </label>
                    <label>Address </label>
                  </div>

                  <div className="col-lg-6 detail-result2 mt-4">
                    {upload7days || usage7days ? (
                      <p className="mb-1 p">
                        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                        {upload7days}/{usage7days}
                      </p>
                    ) : (
                      <p className="mb-1 p">N/A</p>
                    )}
                    {upload30days || usage30days ? (
                      <p className="mb-1 p">
                        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                        {upload30days}/{usage30days}
                      </p>
                    ) : (
                      <p className="mb-1 p">N/A</p>
                    )}
                    {address ? <p className="mt-1 text-wrap">{address}</p> : <p className="mt-1">N/A</p>}
                  </div>
                </div>

                <div className="col-lg-12 d-flex d-fles">
                  <div className="col-lg-6 detail-label1">
                    <label>Basestation</label>
                    <label>Zone</label>
                    <label>Customer Account Number</label>
                  </div>
                  <div className="col-lg-6 detail-result3">
                    <p className="mt-4 mb-1 p text-uppercase">{basestation || 'N/A'}</p>
                    <p className="mb-1 p text-uppercase">{zone || 'N/A'}</p>
                    <p className="mb-1 p">{customerRef || 'N/A'}</p>
                    {/* <p className="mt-1 mb-0">
                      {installationDate && installationDate.user ? (
                        <a className=" noBorder" href={installationDate.contact_information_image || 'N/A'}>
                          Download Customer Image
                        </a>
                      ) : (
                        <p className="mt-1">N/A</p>
                      )}
                    </p> */}
                    {/* <p>
                    <ImageView src={installationDate.pole_image} height="10" width="20" callback="xxxx"/>
                    </p> */}
                    {/* <p className="mt-1 mb-0">
                      {installationDate && installationDate.user ? (
                        <a className=" noBorder" href={installationDate.pole_image}>
                          Download Pole Image
                        </a>
                      ) : (
                        <p className="mt-1">N/A</p>
                      )}
                    </p>
                    <p className="mt-1 mb-0">
                      {installationDate && installationDate.user ? (
                        <a className=" noBorder" href={installationDate.user_kyc}>
                          Download KYC Image
                        </a>
                      ) : (
                        <p className="mt-1">N/A</p>
                      )}
                    </p> */}
                  </div>
                </div>

                <div className="col-lg-12 d-flex d-fles">
                  <div className="col-lg-6 detail-label3">
                    <label>Installation Date</label>
                    {onlinestatus === 'offline' && <label>Last Connected</label>}
                    {`${sessionStorage.getItem('departmentId')}` === `${SALES}` ? <label>Created Date</label> : null}
                    <label>Expiry Date</label>
                  </div>
                  <div className="col-lg-6 detail-result3">
                    <p className="mt-4 mb-0">
                      {installationDate && installationDate.user ? (
                        <Moment format="D MMM YYYY" date={installationDate.created_at} />
                      ) : (
                        'N/A'
                      )}
                    </p>
                    <p className="my-1">
                      {onlinestatus === 'offline' && <Moment format="D MMM YYYY" date={lastconnected} />}
                    </p>
                    {`${sessionStorage.getItem('departmentId')}` === `${SALES}` && (
                      <p className="mt-1">{username ? <Moment format="D MMM YYYY" date={createdDate} /> : 'N/A'}</p>
                    )}
                    <p className="mb-1 p">
                      {<Moment format="D MMM YYYY" date={user !== null && user.value} /> || 'N/A'}
                    </p>
                  </div>
                </div>

                <div className="col-lg-12 d-flex d-fles">
                  <div className="col-lg-6 detail-label4">
                    <label id="status" style={{ display: 'block' }}>
                      Status
                    </label>
                  </div>

                  <div className="col-lg-6 detail-result4">
                    {onlinestatus === 'online' ? (
                      <p className="mt-4 mb-0">
                        <img
                          src="https://res.cloudinary.com/teewhy/image/upload/v1548342137/green_sign"
                          alt="connected-icon"
                        />
                        <span> Online </span>
                      </p>
                    ) : (
                      <p className="mb-0 mt-4">
                        <img
                          src="https://res.cloudinary.com/teewhy/image/upload/v1548342137/Red_sign"
                          alt="connected-icon"
                        />
                        <span> Offline </span>
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-lg-12 d-flex d-fles">
                  <div className="col-lg-6 detail-label3">
                    <label id="kyc" style={{ display: 'block' }}>
                      KYC
                    </label>
                  </div>

                  <div className="col-lg-6 detail-result3">
                    {installationDate && installationDate.user ? (
                      installationDate.user_kyc || installationDate.img_4 ? (
                        <>
                          <p className="p">
                            {installationDate.user_kyc ? (
                              <img
                                src={installationDate.user_kyc}
                                alt="User KYC"
                                style={{ maxWidth: '100%', height: 'auto', objectFit: 'contain' }}
                              />
                            ) : (
                              'N/A'
                            )}
                          </p>
                          <p className="p">
                            {installationDate.img_4 ? (
                              <img
                                src={installationDate.img_4}
                                alt="User Image"
                                style={{ maxWidth: '100%', height: 'auto', objectFit: 'contain' }}
                              />
                            ) : (
                              'N/A'
                            )}
                          </p>
                        </>
                      ) : (
                        'No customer KYC'
                      )
                    ) : (
                      'User not activated'
                    )}
                  </div>
                </div>
                {bizDevUserComment && (
                  <div className="col-lg-12 d-flex d-fles">
                    <div className="col-lg-6 detail-label4">
                      <label id="status" style={{ display: 'block' }}>
                        BizDev User Comment
                      </label>
                    </div>

                    <div className="col-lg-6 detail-result4">
                      <p className="mt-4 mb-0">{bizDevUserComment}</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CustomerDetails.propTypes = {
  load: PropTypes.bool,
  address: PropTypes.string,
  name: PropTypes.string,
  mail: PropTypes.string,
  customerRef: PropTypes.string,
  itemprice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  details: PropTypes.shape(),
  session: PropTypes.shape(),
  createdDate: PropTypes.string,
  username: PropTypes.string,
  installationDate: PropTypes.shape(),
  basestation: PropTypes.string,
  zone: PropTypes.string,
};
export default CustomerDetails;
