/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import { useState, useReducer } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import * as load from '../../constants/types/shared/LoadingTypes';
import { authReducer } from '../../reducers/auth/v2/authReducer';
import * as url from '../../services/api/auth/AuthUrl';
import {
  CUSTOMER_SUPPORT,
  ACCOUNTS,
  OPERATIONS,
  TECHNICAL_SUPPORT,
  SALES,
  RESEARCH_AND_DEVELOPMENT,
  BUSINESS_DEVELOPMENT,
  PROCUREMENT,
  NOC,
  NEW_PRODUCTS,
  EXPRESS_WIFI,
  ADMIN_DEPARTMENT,
  ADMIN,
  PARTNERSHIP,
  VICE_PRESIDENT_SALES,
  FIBRE,
  DATA_ANALYSIS,
  RETENTION
} from '../../utils/departments/helpers';
import { ASSET_TEAM } from '../../utils/config';

export const useAuth = () => {
  const history = useHistory();
  const location = useLocation();
  const loginState = {
    email: '',
    password: '',
  };

  const [login, setLogin] = useState(loginState);

  const [{ isLoading }, dispatch] = useReducer(authReducer, {
    isLoading: false,
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setLogin({
      ...login,
      [name]: value,
    });
  };

  // eslint-disable-next-line consistent-return
  const loginUser = async (data) => {
    dispatch({
      type: load.LOADING_STARTS,
    });

    try {
      const response = await axios.post(`${url.LOG_IN_USER_URL}`, data);
      if (response.data.status === 'success') {
        sessionStorage.setItem('token', response.data._token);
        localStorage.setItem('token', response.data._token);

        try {
          const res = await axios.get(`${url.LOGGED_IN_USER_URL}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          });

          if (res.data.department_id) {
            sessionStorage.setItem('departmentId', res.data.department_id);
            sessionStorage.setItem('roleId', res.data.role_id);
            localStorage.setItem('roleId', res.data.role_id);
            if (location.state && location.state.from) {
              history.push(location.state.from.pathname);
            } else {
              switch (res.data.department_id) {
                case `${CUSTOMER_SUPPORT}`:
                  return history.push('/customer-support');
                case `${ADMIN_DEPARTMENT}`:
                  return history.push('/administration');

                case `${ADMIN}`:
                  return history.push('/super-admin');

                case `${EXPRESS_WIFI}`:
                  return history.push('/express-wifi');

                case `${ACCOUNTS}`:
                  return history.push('/accounts');

                case `${NEW_PRODUCTS}`:
                  return history.push('/new-products');

                case `${OPERATIONS}`:
                  return history.push('/field-support');

                case `${TECHNICAL_SUPPORT}`:
                  return history.push('/field-support');
                case `${DATA_ANALYSIS}`:
                  return history.push('/data-analysis');
                case `${PROCUREMENT}`:
                  return history.push('/procurement');
                case `${FIBRE}`:
                  return history.push('/fibre-team');

                case `${RESEARCH_AND_DEVELOPMENT}`:
                  return history.push('/research-and-development');

                case `${BUSINESS_DEVELOPMENT}`:
                  return history.push('/business-development');

                case `${PARTNERSHIP}`:
                  return history.push('/partnership');

                case `${VICE_PRESIDENT_SALES}`:
                  return history.push('/vp-sales');

                case `${SALES}`:
                  return history.push('/sales');

                case `${NOC}`:
                  return history.push('/network-operations');

                case `${ASSET_TEAM}`:
                  return history.push('/closed-leads');
                
                case `${RETENTION}`:
                  return history.push('/retention-overview');

                default:
                  toast.info(`unable to find user's department`);
              }
            }
          } else {
            toast.info('Please try again!');

            dispatch({
              type: load.LOADING_STOPS,
            });
          }
        } catch (e) {
          dispatch({
            type: load.LOADING_STOPS,
          });

          toast.error('Something went wrong. Please try again!');
        }
      }
    } catch (e) {
      if (e) {
        dispatch({
          type: load.LOADING_STOPS,
        });

        toast.error('Something went wrong!');
        if (e.response.data.message) {
          dispatch({
            type: load.LOADING_STOPS,
          });

          toast.error(e.response.data.message);
        }
      }
    }
  };

  return { login, onChange, loginUser, isLoading };
};
