/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useUserProfile } from '../../../../../hooks/shared/user/useUserProfile';
import Requisitions from '../../../shared/requisition/shared/Requisitions';
import FibreNavBar from '../../../../layouts/fibre/FibreNavBar';
// import profile from '../../../../../assets/img/icons/Profile.png';
import PartnershipNavBar from '../../../../layouts/partnership/PartnershipNavBar';

const PartnershipRequisition = () => {
  const { getUserProfile, profile } = useUserProfile();
  // const { getUserProfile } = useUserProfile();
  // const { firstName } = profile;

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div className="s-layout">
          <main className="s-layout__content col-lg-10 p-0">
            <div className="d-flex col-lg-12 p-2 top-header">
              <PartnershipNavBar currentPage="Overview" profileImage={profile.firstName} />
            </div>
    
        <div className="col-lg-12">
          <Requisitions />
        </div>
      </main>
    </div>
  );
};

export default PartnershipRequisition;
